import React, { useState, useEffect } from "react";
import { Table, Card, Upload, message, Input, Row, Col, Spin, Tag, Button } from "antd";
import { ToTopOutlined, SearchOutlined, CheckCircleFilled, CloseCircleOutlined } from "@ant-design/icons";
import useFetchWithToken from "../../services/api";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import * as XLSX from 'xlsx';

const { Search } = Input;

const getUniqueValues = (data, key) => {
  const uniqueValues = [...new Set(data.map(item => item[key]?.trim()).filter(Boolean))];
  return uniqueValues.map(value => {
    const trimmedValue = value.length > 40 ? `${value.substring(0, 40)}...` : value;
    return { text: trimmedValue, value: trimmedValue };
  });
};

const getUniqueProjectEngagements = (data) => {
  const uniqueCombinations = [...new Set(data.flatMap(item => item.projects.map(project => `${project.Project.title} (${project.projectEngagement})`)))];
  return uniqueCombinations.map(value => ({ text: value, value }));
};

function highlightMatchedText(text, query) {
  if (!text || !query || query.trim() === '') return text;

  const index = text.toLowerCase().indexOf(query.toLowerCase());
  if (index === -1) return text;

  const maxLength = 50;
  const startIndex = Math.max(0, index - maxLength);
  const endIndex = Math.min(text.length, index + query.length + maxLength);

  const prefix = startIndex > 0 ? '...' : '';
  const suffix = endIndex < text.length ? '...' : '';

  const highlightedText = text.substring(startIndex, endIndex)
    .replace(new RegExp(query, 'gi'), (match) => `<span style="background-color: yellow">${match}</span>`);

  return (
    <span dangerouslySetInnerHTML={{ __html: prefix + highlightedText + suffix }} />
  );
}

function highlightMatchedText2(text, query) {
  if (!text || !query || query.trim() === '') return text;

  const index = text.toLowerCase().indexOf(query.toLowerCase());
  if (index === -1) return text;

  const maxLength = 2000000;
  const startIndex = Math.max(0, index - maxLength);
  const endIndex = Math.min(text.length, index + query.length + maxLength);

  const prefix = startIndex > 0 ? '...' : '';
  const suffix = endIndex < text.length ? '...' : '';

  const highlightedText = text.substring(startIndex, endIndex)
    .replace(new RegExp(query, 'gi'), (match) => `<span style="background-color: yellow">${match}</span>`);

  return (
    <span dangerouslySetInnerHTML={{ __html: prefix + highlightedText + suffix }} />
  );
}

function HRCVList() {
  const { postFormData, token } = useFetchWithToken("hrcvs");
  const [uploading, setUploading] = useState(false);
  const [cvData, setCvData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [current, setCurrent] = useState(1);

  const history = useHistory();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (cvData.length > 0) {
      setFilteredData(cvData); // Set filtered data initially
      // Update filter options when data changes
      columns[1].filters = getUniqueValues(cvData, 'country');
      columns[2].filters = getUniqueValues(cvData, 'researchInterest');
      columns.find(col => col.key === 'projects').filters = getUniqueProjectEngagements(cvData);
    }
  }, [cvData]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get("https://kmsbe.frontieri.com/kmsApi/hrcvs", {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setCvData(response.data);
      setFilteredData(response.data); // Set filtered data after fetching
      setLoading(false);
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        history.push('/login');
        message.error("Session expired. Please login again.");
      } else {
        message.error("Unable to load data!");
      }
    }
  };

  const handleRowClick = (record) => {
    history.push(`/hrcvDetails/${record.id}`);
  };

  const handleUpload = async ({ file }) => {
    try {
      setUploading(true);
      const formData = new FormData();
      formData.append("file", file);
      await postFormData(formData, 'uploadHRExcel');
      message.success(`${file.name} uploaded successfully`);
      setUploading(false);
    } catch (error) {
      console.log(error);
      message.error(`${file.name} upload failed`);
      setUploading(false);
    } finally {
      setUploading(false);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await axios.post(`https://kmsbe.frontieri.com/kmsApi/hrcvs/search`, {
        query: searchQuery
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Search Results:', response.data);
      setCvData(response.data);
      setFilteredData(response.data); // Update filtered data with search results
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "HRCVs");
    XLSX.writeFile(wb, "HRCVs.xlsx");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          placeholder={`Search ${dataIndex}`}
          allowClear
          size="small"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onSearch={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button onClick={() => confirm()} size="small" style={{ width: 90 }}>
          Search
        </Button>
        <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
  });

  const columns = [
    {
      title: "SN",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: "2px",
      ...getColumnSearchProps("serialNumber"),
      render: (text) => {
        const truncatedText = text.length > 80 ? `${text.substring(0, 40)}...` : text;
        return highlightMatchedText(truncatedText, searchQuery);
      },
    },
    {
      title: "Name",
      dataIndex: "expertName",
      key: "expertName",
      width: "10%",
      ...getColumnSearchProps("expertName"),
      sorter: (a, b) => a.expertName.localeCompare(b.expertName),
      render: (text) => {
        const truncatedText = text.length > 80 ? `${text.substring(0, 40)}...` : text;
        return highlightMatchedText(truncatedText, searchQuery);
      },
    },
    {
      title: "Gn.",
      dataIndex: "gender",
      key: "gender",
      filters: getUniqueValues(cvData, 'gender'),
      width: "1%",
      onFilter: (value, record) => record.gender?.trim() === value,
      render: (text) => {
        const truncatedText = text.length > 80 ? `${text.substring(0, 40)}...` : text;
        return highlightMatchedText(truncatedText, searchQuery);
      },
    },
    {
      title: "Phone No.",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: "5%",
      ...getColumnSearchProps("phoneNumber"),
      render: (text) => {
        const truncatedText = text.length > 10 ? `${text.substring(0, 10)}...` : text;
        return highlightMatchedText(truncatedText, searchQuery);
      },
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      width: "1%",
      filters: getUniqueValues(cvData, 'region'),
      onFilter: (value, record) => record.region?.trim() === value,
      render: (text) => {
        const truncatedText = text.length > 80 ? `${text.substring(0, 40)}...` : text;
        return highlightMatchedText(truncatedText, searchQuery);
      },
    },
    {
      title: "Lang.",
      dataIndex: "language",
      key: "language",
      sorter: (a, b) => a.language.localeCompare(b.language),
      filters: getUniqueValues(cvData, 'language'),
      onFilter: (value, record) => record.language.trim() === value,
      render: (text) => {
        const truncatedText = text.length > 20 ? `${text.substring(0, 20)}...` : text;
        return highlightMatchedText(truncatedText.trim(), searchQuery);
      },
    },
    {
      title: "Edu. Backg",
      dataIndex: "educationalBackground",
      key: "educationalBackground",
      width: "1%",
      filters: getUniqueValues(cvData, 'educationalBackground'),
      onFilter: (value, record) => record.educationalBackground === value,
      render: (text) => {
        const truncatedText = text.length > 10 ? `${text.substring(0, 10)}...` : text;
        return (
          <span title={text}>
            {highlightMatchedText(truncatedText, searchQuery)}
          </span>
        );
      },
    },
    {
      title: "Resp.",
      dataIndex: "responsibility",
      key: "responsibility",
      width: "5%",
      filters: getUniqueValues(cvData, 'responsibility'),
      onFilter: (value, record) => record.responsibility === value,
      render: (text) => {
        const truncatedText = text.length > 10 ? `${text.substring(0, 10)}...` : text;
        return (
          <span title={text}>
            {highlightMatchedText(truncatedText, searchQuery)}
          </span>
        );
      },
    },
    {
      title: 'Exp.',
      dataIndex: 'yearsOfExperience',
      key: 'yearsOfExperience',
      width:"3%",
      filters: getUniqueValues(cvData, 'yearsOfExperience'),
      onFilter: (value, record) => record.yearsOfExperience === value,
      render: (text) => {
        const truncatedText = text.length > 80 ? `${text.substring(0, 40)}...` : text;
        const displayedText = `${truncatedText} Yrs`; // Append "Years"
        return highlightMatchedText(displayedText, searchQuery);
      },
    },
    {
      title: "Avail.",
      dataIndex: "availablity",  // Updated field name
      key: "availablity",        // Updated field name
      width: "5%",
      filters: [
        { text: 'Available', value: 'Available' },
        { text: 'Unavailable', value: 'Unavailable' },
      ],
      onFilter: (value, record) => (record.availablity || 'Available') === value,  // Updated field name
      render: (availablity) => {  // Updated parameter name
        const availability = availablity || 'Available';  // Updated variable name
        return availability === 'Available' ? <CheckCircleFilled style={{ color: 'darkgreen', fontSize: '20px' , position:'center'}} /> : <CloseCircleOutlined style={{ color: 'darkred', fontSize: '20px', position: 'center' }} />;
      }
    },
    {
      title: "Projects",
      dataIndex: "projects",
      key: "projects",
      width: "5%",
      filters: getUniqueProjectEngagements(cvData),
      onFilter: (value, record) => record.projects.some(project => `${project.Project.title} (${project.projectEngagement})` === value),
      render: (projects) => (
        <>
          {projects && projects.length > 0 ? (
            projects.map(project => (
              <div key={project.id} style={{ marginBottom: '5px' }}>
                <Tag color="darkblue" style={{ color: 'white' }} title={project.Project.title}>
                  {project.Project.title.length > 20 ? `${project.Project.title.substring(0, 10)}...` : project.Project.title}
                </Tag>
                <Tag color="darkgreen" style={{ color: 'white' }} title={project.projectEngagement}>
                  {project.projectEngagement.length > 20 ? `${project.projectEngagement.substring(0, 10)}...` : project.projectEngagement}
                </Tag>
              </div>
            ))
          ) : (
            <Tag color="darkred" style={{ color: 'white' }}>No Projects</Tag>
          )}
        </>
      )
    }
  ];

  return (
    <div className="tabled">
      {/* <Row gutter={[24, 0]}>
        <Col span={12}></Col>
        <Col span={12}>
          <Search
            placeholder="Search CV"
            allowClear
            enterButton={<SearchOutlined style={{ fontSize: '25px' }} />}
            onSearch={handleSearch}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </Col>
      </Row> */}
      {loading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spin size="large" />
        </div>
      ) : (
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Card>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                  })}
                  dataSource={filteredData}
                  pagination={{
                    current: current,
                    pageSize: pageSize,
                    onChange: (page, pageSize) => {
                      setCurrent(page);
                      setPageSize(pageSize);
                    },
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '50'],
                  }}
                  className="ant-border-space"
                />
              </div>
            </Card>
            <Card bordered={false}>
              <div className="uploadfile pb-15 shadow-none">
                <Upload
                  name="file"
                  customRequest={handleUpload}
                  beforeUpload={(file) => {
                    const isExcel = file.type === "application/vnd.ms-excel" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                    if (!isExcel) {
                      message.error("You can only upload Excel files!");
                    }
                    return isExcel;
                  }}
                >
                  <Button type="dashed" className="ant-full-box" icon={<ToTopOutlined />} loading={uploading}>
                    Click to Upload
                  </Button>
                </Upload>
              </div>
              <Button type="primary" onClick={exportToExcel} style={{ marginTop: '10px' }}>
                Export to Excel
              </Button>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default HRCVList;
