import { useState, useEffect, useMemo } from 'react';
import { Card, Col, Row, Typography, Table ,Tooltip, Select,DatePicker, Radio} from 'antd';
import html2canvas from 'html2canvas';
import { Modal, Checkbox } from 'antd';

import axios from 'axios';
import { Bar, Pie, Line, Doughnut} from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { DashboardOutlined ,  UserOutlined,
  QuestionCircleFilled,
  ExportOutlined,BookFilled,BuildFilled,TeamOutlined,ClockCircleFilled,CheckCircleFilled,
  FileTextOutlined,
  FileDoneOutlined,
  DeploymentUnitOutlined,
  FileOutlined} from '@ant-design/icons';
import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, ImageRun } from 'docx';
import fileDownload from 'js-file-download';


// Register the necessary scale types
Chart.register(...registerables);
const { RangePicker } = DatePicker;
const { Option } = Select;
// const ChartWithDatePicker = ({ title, dataKey, data, fetchData2, memoizedGetChartData }) => {
//   const handleDateChange = (dates) => {
//     if (dates) {
//       const [start, end] = dates;
//       fetchData2(start.toISOString(), end.toISOString(), dataKey);
//     }
//   };

//   return (
//     <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
//       <Card bordered={false} className="criclebox h-full">
//         <div id={title}>
//           <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//             <h2>{title}</h2>
//             <RangePicker onChange={handleDateChange} />
//           </div>
//           {data && <Bar data={memoizedGetChartData(data)} options={{ maintainAspectRatio: true }} />}
//         </div>
//       </Card>
//     </Col>
//   );
// };
// const ChartWithDatePicker = ({ title, dataKey, data, fetchData2, memoizedGetChartData }) => {
//   const [chartType, setChartType] = useState('Bar');

//   const handleDateChange = (dates) => {
//     if (dates) {
//       const [start, end] = dates;
//       fetchData2(start.toISOString(), end.toISOString(), dataKey);
//     }
//   };

//   const handleChartTypeChange = (value) => {
//     setChartType(value);
//   };

//   let ChartComponent;
//   switch (chartType) {
//     case 'Line':
//       ChartComponent = Line;
//       break;
//     case 'Pie':
//       ChartComponent = Pie;
//       break;
//     case 'Doughnut':
//       ChartComponent = Doughnut;
//       break;
//     case 'Bar':
//     default:
//       ChartComponent = Bar;
//       break;
//   }

//   return (
//     <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
//       <Card bordered={false} className="criclebox h-full">
//         <div id={title}>
//           <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//             <h2>{title}</h2>
//             <div>
//               <RangePicker onChange={handleDateChange} />
//               <Select
//                 defaultValue="Bar"
//                 style={{ width: 100, marginLeft: 10 }}
//                 onChange={handleChartTypeChange}
//               >
//                 <Option value="Bar">Bar</Option>
//                 <Option value="Line">Line</Option>
//                 <Option value="Pie">Pie</Option>
//                 <Option value="Doughnut">Doughnut</Option>
//               </Select>
//             </div>
//           </div>
//           {data && <ChartComponent data={memoizedGetChartData(data)} options={{ maintainAspectRatio: true }} />}
//         </div>
//       </Card>
//     </Col>
//   );
// };
// const ChartWithDatePicker = ({ title, dataKey, data, fetchData2, memoizedGetChartData }) => {
//   const [chartType, setChartType] = useState('Bar');

//   const handleDateChange = (dates) => {
//     if (dates) {
//       const [start, end] = dates;
//       fetchData2(start.toISOString(), end.toISOString(), dataKey);
//     }
//   };

//   const handleChartTypeChange = (value) => {
//     setChartType(value);
//   };

//   const handleExportChart = async (divId, chartTitle) => {
//     const element = document.getElementById(divId);
//     const canvas = await html2canvas(element);
//     const link = document.createElement('a');
//     link.href = canvas.toDataURL('image/png');
//     link.download = `${chartTitle}.png`;
//     link.click();
//   };

//   let ChartComponent;
//   switch (chartType) {
//     case 'Line':
//       ChartComponent = Line;
//       break;
//     case 'Pie':
//       ChartComponent = Pie;
//       break;
//     case 'Doughnut':
//       ChartComponent = Doughnut;
//       break;
//     case 'Bar':
//     default:
//       ChartComponent = Bar;
//       break;
//   }

//   return (
//     <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
//       <Card bordered={false} className="criclebox h-full">
//         <div id={`div-${dataKey}`}>
//           <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//             <h2>{title}</h2>
//             <div>
//               <RangePicker onChange={handleDateChange} />
//               <Select
//                 defaultValue="Bar"
//                 style={{ width: 100, marginLeft: 10 }}
//                 onChange={handleChartTypeChange}
//               >
//                 <Option value="Bar">Bar</Option>
//                 <Option value="Line">Line</Option>
//                 <Option value="Pie">Pie</Option>
//                 <Option value="Doughnut">Doughnut</Option>
//               </Select>
//               <ExportOutlined
//                 className="export-icon"
//                 style={{ marginLeft: 10 }}
//                 onClick={() => handleExportChart(`div-${dataKey}`, title)}
//               />
//             </div>
//           </div>
//           {data && <ChartComponent data={memoizedGetChartData(data)} options={{ maintainAspectRatio: true }} />}
//         </div>
//       </Card>
//     </Col>
//   );
// };

// const ChartWithDatePicker = ({ title, dataKey, data, fetchData2, memoizedGetChartData }) => {
//   const [chartType, setChartType] = useState('Bar');
//   const [modalVisible, setModalVisible] = useState(false);
//   const [includeTitle, setIncludeTitle] = useState(true);
//   const [includeDatePicker, setIncludeDatePicker] = useState(true);

//   const handleDateChange = (dates) => {
//     if (dates) {
//       const [start, end] = dates;
//       fetchData2(start.toISOString(), end.toISOString(), dataKey);
//     }
//   };

//   const handleChartTypeChange = (value) => {
//     setChartType(value);
//   };

//   const handleExportChart = async (divId, chartTitle) => {
//     const div = document.getElementById(divId);
//     const exportButton = div.querySelector('.export-icon');
//     const titleElement = div.querySelector('h2');
//     const datePickers = div.querySelectorAll('.ant-picker');

//     if (exportButton) exportButton.style.display = 'none';
//     if (titleElement && !includeTitle) titleElement.style.display = 'none';
//     if (datePickers && !includeDatePicker) datePickers.forEach(picker => picker.style.display = 'none');

//     const canvas = await html2canvas(div);

//     if (exportButton) exportButton.style.display = 'block';
//     if (titleElement && !includeTitle) titleElement.style.display = 'block';
//     if (datePickers && !includeDatePicker) datePickers.forEach(picker => picker.style.display = 'block');

//     const image = canvas.toDataURL('image/png');
//     const link = document.createElement('a');
//     link.href = image;
//     link.download = `${chartTitle}.png`;
//     link.click();
//   };

//   const handleExportConfirmation = () => {
//     setModalVisible(false);
//     handleExportChart(`div-${dataKey}`, title);
//   };

//   let ChartComponent;
//   switch (chartType) {
//     case 'Line':
//       ChartComponent = Line;
//       break;
//     case 'Pie':
//       ChartComponent = Pie;
//       break;
//     case 'Doughnut':
//       ChartComponent = Doughnut;
//       break;
//     case 'Bar':
//     default:
//       ChartComponent = Bar;
//       break;
//   }

//   return (
//     <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
//       <Card bordered={false} className="criclebox h-full">
//         <div id={`div-${dataKey}`}>
//           <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//             <h2>{title}</h2>
//             <div>
//               <RangePicker onChange={handleDateChange} />
//               <Select
//                 defaultValue="Bar"
//                 style={{ width: 40, marginLeft: 5 }}
//                 onChange={handleChartTypeChange}
//               >
//                 <Option value="Bar">Bar</Option>
//                 <Option value="Line">Line</Option>
//                 <Option value="Pie">Pie</Option>
//                 <Option value="Doughnut">Doughnut</Option>
//               </Select>
//               <ExportOutlined
//                 className="export-icon"
//                 style={{ marginLeft: 10 }}
//                 onClick={() => setModalVisible(true)}
//               />
//             </div>
//           </div>
//           {data && <ChartComponent data={memoizedGetChartData(data)} options={{ maintainAspectRatio: true }} />}
//         </div>
//       </Card>
//       <Modal
//         title="Export Options"
//         visible={modalVisible}
//         onCancel={() => setModalVisible(false)}
//         onOk={handleExportConfirmation}
//       >
//         <Checkbox checked={includeTitle} onChange={(e) => setIncludeTitle(e.target.checked)}>
//           Include Title
//         </Checkbox>
//         <Checkbox checked={includeDatePicker} onChange={(e) => setIncludeDatePicker(e.target.checked)}>
//           Include Date Picker
//         </Checkbox>
//       </Modal>
//     </Col>
//   );
// };


const ChartWithDatePicker = ({ title, dataKey, data, fetchData2, memoizedGetChartData }) => {
  const [chartType, setChartType] = useState('Bar');
  const [modalVisible, setModalVisible] = useState(false);
  const [includeTitle, setIncludeTitle] = useState(true);
  const [includeDatePicker, setIncludeDatePicker] = useState(true);
  const [exportOption, setExportOption] = useState("excel"); // Default to Excel

  const handleDateChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      fetchData2(start.toISOString(), end.toISOString(), dataKey);
    }
  };

  const handleChartTypeChange = (value) => {
    setChartType(value);
  };

  const handleExportChart = async (divId, chartTitle) => {
    const div = document.getElementById(divId);
    const exportButton = div.querySelector('.export-icon');
    const titleElement = div.querySelector('h2');
    const datePickers = div.querySelectorAll('.ant-picker');

    if (exportButton) exportButton.style.display = 'none';
    if (titleElement && !includeTitle) titleElement.style.display = 'none';
    if (datePickers && !includeDatePicker) datePickers.forEach(picker => picker.style.display = 'none');

    const canvas = await html2canvas(div);

    if (exportButton) exportButton.style.display = 'block';
    if (titleElement && !includeTitle) titleElement.style.display = 'block';
    if (datePickers && !includeDatePicker) datePickers.forEach(picker => picker.style.display = 'block');

    const image = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = image;
    link.download = `${chartTitle}.png`;
    link.click();
  };

  const handleExportConfirmation = () => {
    setModalVisible(false);
    handleExport(exportOption);
  };
  const handleExportToExcel = () => {
    // Convert the data to a two-dimensional array
    const excelData = data.map(item => [item.date, item.count]);
  
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
  
    // Add a worksheet to the workbook
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'data.xlsx');
  };
  
  const handleExport = (option) => {
    if (option === "excel") {
      handleExportToExcel();
    } else if (option === "word") {
      handleExportToWord();
    } else if (option === "image") {
      handleExportChart(`div-${dataKey}`, title);
    }
  };
  
 const handleExportToWord = () => {
  // Create an empty HTML table
  let htmlTable = '<table>';

  // Add table headers
  htmlTable += '<thead><tr>';
  htmlTable += '<th>Date</th>';
  htmlTable += '<th>Count</th>';
  htmlTable += '</tr></thead>';

  // Add table rows with data
  htmlTable += '<tbody>';
  data.forEach(item => {
    htmlTable += `<tr><td>${item.date}</td><td>${item.count}</td></tr>`;
  });
  htmlTable += '</tbody>';

  // Close the HTML table
  htmlTable += '</table>';

  // Create a Blob containing the HTML content
  const blob = new Blob(['<!DOCTYPE html><html><head><meta charset="UTF-8"></head><body>' + htmlTable + '</body></html>'], { type: 'text/html' });

  // Create a link element
  const link = document.createElement('a');

  // Set the link's attributes
  link.href = URL.createObjectURL(blob);
  link.download = 'data.docx';

  // Simulate a click on the link to trigger the download
  link.click();
};

  

  let ChartComponent;
  switch (chartType) {
    case 'Line':
      ChartComponent = Line;
      break;
    case 'Pie':
      ChartComponent = Pie;
      break;
    case 'Doughnut':
      ChartComponent = Doughnut;
      break;
    case 'Bar':
    default:
      ChartComponent = Bar;
      break;
  }

  return (
    <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
      <Card bordered={false} className="criclebox h-full">
        <div id={`div-${dataKey}`}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2>{title}</h2>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: 200 }}>
                <RangePicker onChange={handleDateChange} style={{ width: '100%' }} />
              </div>
              <Select
                defaultValue="Bar"
                style={{ width: 100, marginLeft: 10 }}
                onChange={handleChartTypeChange}
              >
                <Option value="Bar">Bar</Option>
                <Option value="Line">Line</Option>
                <Option value="Pie">Pie</Option>
                <Option value="Doughnut">Doughnut</Option>
              </Select>
              <ExportOutlined
                className="export-icon"
                style={{ marginLeft: 10 }}
                onClick={() => setModalVisible(true)}
              />
            </div>
          </div>
          {data && <ChartComponent data={memoizedGetChartData(data)} options={{ maintainAspectRatio: true }} />}
        </div>
      </Card>
      <Modal
      title="Export Options"
      visible={modalVisible}
      onCancel={() => setModalVisible(false)}
      onOk={handleExportConfirmation}
    >
      <Checkbox checked={includeTitle} onChange={(e) => setIncludeTitle(e.target.checked)}>
        Include Title
      </Checkbox>
      <Checkbox checked={includeDatePicker} onChange={(e) => setIncludeDatePicker(e.target.checked)}>
        Include Date Picker
      </Checkbox>
      <Radio.Group value={exportOption} onChange={(e) => setExportOption(e.target.value)}>
        <Radio value="excel">Export to Excel</Radio>
        <Radio value="word">Export to Word</Radio>
        <Radio value="image">Export as Image</Radio>
      </Radio.Group>
    </Modal>
    </Col>
  );
};
function Home() {
  const { Title } = Typography;
  const [counts, setCounts] = useState(null);
  const [timeSeriesData, setTimeSeriesData] = useState(null);
  const [tpByMonth, setTPByMonth] = useState(null);

  const [tpByTeam, setTPByTeam] = useState(null);

  const [rfpByTeamMember, setRfpByTeamMember] = useState(null);
  const [tpSummary, setTPSummary] = useState(null);
  const [averageOfferingValue, setAverageOfferingValue] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateTPByClient, setStartDateTPByClient] = useState(null);
  const [endDateTPByClient, setEndDateTPByClient] = useState(null);
  const [startDateRFPByTeamMember, setStartDateRFPByTeamMember] = useState(null);
  const [endDateRFPByTeamMember, setEndDateRFPByTeamMember] = useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [allTimeSeriesData, setAllTimeSeriesData] = useState({
    CVs: null,
    TPs: null,
    RFPs: null,
    IRs: null,
    FRs: null,
  });
  
  const fetchData2 = async (startDate, endDate, type) => {
    try {
      const params = {};
      if (startDate && endDate) {
        params.startDate = startDate;
        params.endDate = endDate;
      }
      const response = await axios.get('https://kmsbe.frontieri.com/kmsApi/dashboard/time-series', { params });

      const renamedData = {};
      Object.keys(response.data).forEach((key) => {
        switch (key) {
          case 'cvCounts':
            renamedData['CVs'] = response.data[key];
            break;
          case 'tpCounts':
            renamedData['TPs'] = response.data[key];
            break;
          case 'rfpCounts':
            renamedData['RFPs'] = response.data[key];
            break;
          case 'userCounts':
            renamedData['Users'] = response.data[key];
            break;
          case 'frCounts':
            renamedData['FRs'] = response.data[key];
            break;
          case 'irCounts':
            renamedData['IRs'] = response.data[key];
            break;
          default:
            renamedData[key] = response.data[key];
        }
      });
      
      setAllTimeSeriesData((prevData) => ({
        ...prevData,
        [type]: renamedData[type],
      }));
    } catch (error) {
      console.error('Error fetching time series data:', error);
    }
  };

  useEffect(() => {
    fetchData2(null, null, 'CVs');
    fetchData2(null, null, 'TPs');
    fetchData2(null, null, 'RFPs');
    fetchData2(null, null, 'IRs');
    fetchData2(null, null, 'FRs');
    // Add more calls for other types if needed
  }, []);

  
  useEffect(() => {
    fetchData2(); // Call fetchData2 initially
  }, []);
  

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setStartDate(dates[0].toISOString().split('T')[0]);
      setEndDate(dates[1].toISOString().split('T')[0]);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        let params = {};

        // Check if startDate and endDate are set
        if (startDate && endDate) {
          params.startDate = startDate;
          params.endDate = endDate;
        }

        const response = await axios.get('https://kmsbe.frontieri.com/kmsApi/dashboard/tpByTeam', { params });

        // Process response data as needed
        // For example, renaming keys
        const renamedData = {};
        Object.keys(response.data).forEach((key) => {
          switch (key) {
            case 'cvCounts':
              renamedData['CVs'] = response.data[key];
              break;
            case 'tpCounts':
              renamedData['TPs By Team'] = response.data[key];
              break;
            case 'rfpCounts':
              renamedData['RFPs'] = response.data[key];
              break;
            case 'userCounts':
              renamedData['Users'] = response.data[key];
              break;
            case 'frCounts':
              renamedData['Final Reports'] = response.data[key];
              break;
            case 'irCounts':
              renamedData['Inception Reports'] = response.data[key];
              break;
            // Add more cases as needed
            default:
              renamedData[key] = response.data[key];
          }
        });

        setTPByTeam(renamedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [startDate, endDate]); // Fetch data whenever startDate or endDate changes


  useEffect(() => {
    const fetchData = async () => {
      try {
        let params = {};

        // Check if startDate and endDate are set
        if (startDateTPByClient && endDateTPByClient) {
          params.startDate = startDateTPByClient;
          params.endDate = endDateTPByClient;
        }

        const response = await axios.get('https://kmsbe.frontieri.com/kmsApi/dashboard/tpByClient', { params });

        // Process response data as needed
        // For example, renaming keys
        const renamedData = {};
        Object.keys(response.data).forEach((key) => {
          switch (key) {
            case 'cvCounts':
              renamedData['CVs'] = response.data[key];
              break;
            case 'tpCounts':
              renamedData['TPs By Client'] = response.data[key];
              break;
            case 'rfpCounts':
              renamedData['RFPs'] = response.data[key];
              break;
            case 'userCounts':
              renamedData['Users'] = response.data[key];
              break;
            case 'frCounts':
              renamedData['Final Reports'] = response.data[key];
              break;
            case 'irCounts':
              renamedData['Inception Reports'] = response.data[key];
              break;
            // Add more cases as needed
            default:
              renamedData[key] = response.data[key];
          }
        });

        setTPByMonth(renamedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [startDateTPByClient, endDateTPByClient]); // Fetch data whenever startDate or endDate changes

  const handleDateChangeForTpByClient = (dates) => {
    if (dates && dates.length === 2) {
      setStartDateTPByClient(dates[0].toISOString().split('T')[0]);
      setEndDateTPByClient(dates[1].toISOString().split('T')[0]);
    }
  };
  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };



  useEffect(() => {
    axios
      .get('https://kmsbe.frontieri.com/kmsApi/dashboard')
      .then((response) => {
        setCounts(response.data);
      })
      .catch((error) => {
        console.error('Error fetching dashboard data:', error);
      });
      axios
      .get('https://kmsbe.frontieri.com/kmsApi/dashboard/generateTPReportSummary')
      .then((response) => {
        setTPSummary(response.data.summary);
        setAverageOfferingValue(response.data.averageOfferingValue);

      })
      .catch((error) => {
        console.error('Error fetching dashboard data:', error);
      });

    // axios
    //   .get('https://kmsbe.frontieri.com/kmsApi/dashboard/tpByClient')
    //   .then((response) => {
    //     // Rename keys to display desired titles
    //     const renamedData = {};
    //     Object.keys(response.data).forEach((key) => {
    //       switch (key) {
    //         case 'cvCounts':
    //           renamedData['CVs'] = response.data[key];
    //           break;
    //         case 'tpCounts':
    //           renamedData['TPs By Client'] = response.data[key];
    //           break;
    //         case 'rfpCounts':
    //           renamedData['RFPs'] = response.data[key];
    //           break;
    //         case 'userCounts':
    //           renamedData['Users'] = response.data[key];
    //           break;
    //         case 'frCounts':
    //           renamedData['Final Reports'] = response.data[key];
    //           break;
    //         case 'irCounts':
    //           renamedData['Inception Reports'] = response.data[key];
    //           break;
    //         // Add more cases as needed
    //         default:
    //           renamedData[key] = response.data[key];
    //       }
    //     });
    //     setTPByMonth(renamedData);
    //   })
    //   .catch((error) => {
    //     console.error('Error fetching TPs by client data:', error);
    //   });
  
      // axios
      // .get('https://kmsbe.frontieri.com/kmsApi/dashboard/rfpByTeamMember')
      // .then((response) => {
      //   // Rename keys to display desired titles
      //   const renamedData = {};
      //   Object.keys(response.data).forEach((key) => {
      //     switch (key) {
         
      //       case 'rfpCounts':
      //         renamedData['RFPs By BD Officers'] = response.data[key];
      //         break;
          
      //       // Add more cases as needed
      //       default:
      //         renamedData[key] = response.data[key];
      //     }
      //   });
      //   setRfpByTeamMember(renamedData);
      // })
      // .catch((error) => {
      //   console.error('Error fetching TPs by client data:', error);
      // });
      // axios
      // .get('https://kmsbe.frontieri.com/kmsApi/dashboard/tpByTeam')
      // .then((response) => {
      //   // Rename keys to display desired titles
      //   const renamedData = {};
      //   Object.keys(response.data).forEach((key) => {
      //     switch (key) {
      //       case 'cvCounts':
      //         renamedData['CVs'] = response.data[key];
      //         break;
      //       case 'tpCounts':
      //         renamedData['TPs By Team'] = response.data[key];
      //         break;
      //       case 'rfpCounts':
      //         renamedData['RFPs'] = response.data[key];
      //         break;
      //       case 'userCounts':
      //         renamedData['Users'] = response.data[key];
      //         break;
      //       case 'frCounts':
      //         renamedData['Final Reports'] = response.data[key];
      //         break;
      //       case 'irCounts':
      //         renamedData['Inception Reports'] = response.data[key];
      //         break;
      //       // Add more cases as needed
      //       default:
      //         renamedData[key] = response.data[key];
      //     }
      //   });
      //   setTPByTeam(renamedData);
      // })
      // .catch((error) => {
      //   console.error('Error fetching TPs by client data:', error);
      // });
  
    axios
      .get('https://kmsbe.frontieri.com/kmsApi/dashboard/time-series')
      .then((response) => {
        // Rename keys to display desired titles
        const renamedData = {};
        Object.keys(response.data).forEach((key) => {
          switch (key) {
            case 'cvCounts':
              renamedData['CVs'] = response.data[key];
              break;
            case 'tpCounts':
              renamedData['TPs'] = response.data[key];
              break;
            case 'rfpCounts':
              renamedData['RFPs'] = response.data[key];
              break;
            case 'userCounts':
              renamedData['Users'] = response.data[key];
              break;
            case 'frCounts':
              renamedData['Final Reports'] = response.data[key];
              break;
            case 'irCounts':
              renamedData['Inception Reports'] = response.data[key];
              break;
            // Add more cases as needed
            default:
              renamedData[key] = response.data[key];
          }
        });
      //  setTimeSeriesData(renamedData);
      })
      .catch((error) => {
        console.error('Error fetching time series data:', error);
      });
  }, []);



  useEffect(() => {
    const fetchData = async () => {
      try {
        let params = {};

        // Check if startDate and endDate are set
        if (startDateRFPByTeamMember && endDateRFPByTeamMember) {
          params.startDate = startDateRFPByTeamMember;
          params.endDate = endDateRFPByTeamMember;
        }

        const response = await axios.get('https://kmsbe.frontieri.com/kmsApi/dashboard/rfpByTeamMember', { params });

        // Process response data as needed
        // For example, renaming keys
        const renamedData = {};
        Object.keys(response.data).forEach((key) => {
          switch (key) {
            case 'cvCounts':
              renamedData['CVs'] = response.data[key];
              break;
            case 'tpCounts':
              renamedData['TPs By Client'] = response.data[key];
              break;
            case 'rfpCounts':
              renamedData['RFPs By BDO'] = response.data[key];
              break;
            case 'userCounts':
              renamedData['Users'] = response.data[key];
              break;
            case 'frCounts':
              renamedData['Final Reports'] = response.data[key];
              break;
            case 'irCounts':
              renamedData['Inception Reports'] = response.data[key];
              break;
            // Add more cases as needed
            default:
              renamedData[key] = response.data[key];
          }
        });

        setRfpByTeamMember(renamedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [startDateRFPByTeamMember, endDateRFPByTeamMember]); // Fetch data whenever startDate or endDate changes

  const handleDateChangeRFPByTeamMember = (dates) => {
    if (dates && dates.length === 2) {
      setStartDateRFPByTeamMember(dates[0].toISOString().split('T')[0]);
      setEndDateRFPByTeamMember(dates[1].toISOString().split('T')[0]);
    }
  };
  const countItems = [
    { key: 'userCount', label: 'Users', icon: <UserOutlined /> },
    { key: 'cvCount', label: 'CVs', icon: <FileOutlined /> },
    { key: 'rfpCount', label: 'RFPS', icon: <QuestionCircleFilled /> },
    { key: 'tpCount', label: 'TPS', icon: <BookFilled /> },
    { key: 'eoipCount', label: 'EOI', icon: <BookFilled /> },
    { key: 'reoiCount', label: 'REOI', icon: <QuestionCircleFilled /> },


  
    { key: 'projectCount', label: 'Firm Experience', icon: <BuildFilled /> },
    { key: 'teamCount', label: 'Teams', icon: <TeamOutlined /> },
    { key: 'irCount', label: 'Inception Reports', icon: <ClockCircleFilled /> },
    { key: 'frCount', label: 'Final Reports', icon: <CheckCircleFilled /> },
  ];

  const groupDataByMonth = (data) => {
    const groupedData = {};
    data.forEach((entry) => {
      if (!groupedData[entry.date]) {
        groupedData[entry.date] = [];
      }
      groupedData[entry.date].push({ client: entry.client, count: parseInt(entry.count) });
    });
    return groupedData;
  };

  const groupDataByMonthTeam = (data) => {
    console.log("aksdns,d", data)
    const groupedData = {};
    data.forEach((entry) => {
      const { date, teamName, count } = entry;
      if (!groupedData[date]) {
        groupedData[date] = {};
      }
      if (!groupedData[date][teamName]) {
        groupedData[date][teamName] = parseInt(count);
      } else {
        groupedData[date][teamName] += parseInt(count);
      }
    });
    return groupedData;
  };
  const groupDataByMonthTeamMember = (data) => {
    const groupedData = {};
    data.forEach((entry) => {
      const { date, firstName, count } = entry;
      if (!groupedData[date]) {
        groupedData[date] = {};
      }
      if (!groupedData[date][firstName]) {
        groupedData[date][firstName] = parseInt(count);
      } else {
        groupedData[date][firstName] += parseInt(count);
      }
    });
    return groupedData;
  };
  const getChartData = (data) => {
    console.log('getChartData called');
    return {
      labels: data.map((entry) => entry.date),
      datasets: [
        {
          label: 'Count',
          backgroundColor: '#e94f24',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(75,192,192,0.6)',
          hoverBorderColor: 'rgba(75,192,192,1)',
          data: data.map((entry) => entry.count),
        },
      ],
    };
  };

  
  const getChartDataForTPByClient = (data) => {
    const predefinedColors = [
      '#FF6384', '#36A2EB', '#FFCE56', '#8D6E63', '#66BB6A', '#AB47BC',
      '#EC407A', '#42A5F5', '#26C6DA', '#7E57C2', '#26A69A', '#D4E157',
      '#5C6BC0', '#7CB342', '#F06292', '#9575CD', '#64B5F6', '#81C784',
      '#E57373', '#5C6BC0', '#7CB342', '#F06292', '#9575CD', '#64B5F6'
    ];
  
    const datasets = [];
  
    // Create a dataset for each client
    const clients = new Set();
    Object.values(data).forEach((monthData) => {
      monthData.forEach((entry) => {
        clients.add(entry.client);
      });
    });
  
    clients.forEach((client, index) => {
      const dataPoints = [];
      const color = predefinedColors[index % predefinedColors.length];
  
      Object.entries(data).forEach(([month, monthData]) => {
        const clientData = monthData.find((entry) => entry.client === client);
        dataPoints.push(clientData ? clientData.count : 0);
      });
  
      datasets.push({
        label: client,
        backgroundColor: color,
        borderColor: color, // Use the same color as backgroundColor
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.6)',
        hoverBorderColor: color, // Use the same color as backgroundColor
        data: dataPoints,
      });
    });
  
    return {
      labels: Object.keys(data),
      datasets,
      title: {
        display: true,
        text: 'TPs By Client',
      },
    };
  };
  const getChartDataForTPByTeam = (data) => {
    const predefinedColors = [
      '#FF6384', '#36A2EB', '#FFCE56', '#8D6E63', '#66BB6A', '#AB47BC',
      '#EC407A', '#42A5F5', '#26C6DA', '#7E57C2', '#26A69A', '#D4E157',
      '#5C6BC0', '#7CB342', '#F06292', '#9575CD', '#64B5F6', '#81C784',
      '#E57373', '#5C6BC0', '#7CB342', '#F06292', '#9575CD', '#64B5F6'
    ];
  
    const datasets = [];
  
    // Create a dataset for each team
    const teams = new Set();
    Object.values(data).forEach((monthData) => {
      Object.keys(monthData).forEach((teamName) => {
        teams.add(teamName);
      });
    });
  
    teams.forEach((teamName, index) => {
      const dataPoints = [];
      const color = predefinedColors[index % predefinedColors.length];
  
      Object.entries(data).forEach(([month, monthData]) => {
        dataPoints.push(monthData[teamName] || 0);
      });
  
      datasets.push({
        label: teamName,
        backgroundColor: color,
        borderColor: color, // Use the same color as backgroundColor
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.6)',
        hoverBorderColor: color, // Use the same color as backgroundColor
        data: dataPoints,
      });
    });
  
    return {
      labels: Object.keys(data),
      datasets,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'chartTitle',
          },
        },
      },
    };
  };
  const columns = [
    { title: 'No', dataIndex: 'No', key: 'No' },
    { title: 'Client', dataIndex: 'Client', key: 'Client' ,    
       render: (text) => <Tooltip title={text}>{text.length > 5 ? `${text.substring(0, 5)}...` : text}</Tooltip>
  },
    { title: 'No Of TP Submitted', dataIndex: 'NoofTPSubmitted', key: 'NoofTPSubmitted' },
    { title: 'Average Value', dataIndex: 'AverageValue', key: 'AverageValue' },

  
  ];
  const getChartDataForRFPByTeamMember = (data) => {
    const predefinedColors = [
      '#FF6384', '#36A2EB', '#FFCE56', '#8D6E63', '#66BB6A', '#AB47BC',
      '#EC407A', '#42A5F5', '#26C6DA', '#7E57C2', '#26A69A', '#D4E157',
      '#5C6BC0', '#7CB342', '#F06292', '#9575CD', '#64B5F6', '#81C784',
      '#E57373', '#5C6BC0', '#7CB342', '#F06292', '#9575CD', '#64B5F6'
    ];
  
    const datasets = [];
  
    // Create a dataset for each team
    const teams = new Set();
    Object.values(data).forEach((monthData) => {
      Object.keys(monthData).forEach((firstName) => {
        teams.add(firstName);
      });
    });
  
    teams.forEach((firstName, index) => {
      const dataPoints = [];
      const color = predefinedColors[index % predefinedColors.length];
  
      Object.entries(data).forEach(([month, monthData]) => {
        dataPoints.push(monthData[firstName] || 0);
      });
  
      datasets.push({
        label: firstName,
        backgroundColor: color,
        borderColor: color, // Use the same color as backgroundColor
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.6)',
        hoverBorderColor: color, // Use the same color as backgroundColor
        data: dataPoints,
      });
    });
  
    return {
      labels: Object.keys(data),
      datasets,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'chartTitle',
          },
        },
      },
    };
  };
   
  
  const memoizedGetChartData = useMemo(() => getChartData, []);
  const memoizedGetChartDataForTPByClient = useMemo(() => getChartDataForTPByClient, []);
  const memoizedGetChartDataForTPByTeam = useMemo(() => getChartDataForTPByTeam, []);

  const memoizedGetChartDataForRFPByTeamMember = useMemo(() => getChartDataForRFPByTeamMember, []);
  const [exporting, setExporting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [includeTitle, setIncludeTitle] = useState(true);
  const [includeDatePicker, setIncludeDatePicker] = useState(true);
  const [modalVisibleForRFP, setModalVisibleForRFP] = useState(false);
  const [modalVisibleForTPByClient, setModalVisibleForTPByClient] = useState(false);

  const [modalVisibleForTS, setModalVisibleForTS] = useState(false);


  // Function to handle export
  const handleExportDiv = (divId, month) => {
    setModalVisible(true); // Display modal
  };
  
  // Function to handle export confirmation
  const handleExportConfirmation = (divId, month) => {
    setModalVisible(false); // Close modal
    setExporting(true); // Start exporting process
    // Hide export button, title, and date picker if necessary
    const div = document.getElementById(divId);
    const exportButton = div.querySelector('button');
    const title = div.querySelector('h2');
    const datePickers = div.querySelectorAll('.ant-picker');
    if (exportButton) exportButton.style.display = 'none';
    if (title && !includeTitle) title.style.display = 'none';
    if (datePickers && !includeDatePicker) datePickers.forEach(picker => picker.style.display = 'none');
  
    html2canvas(div).then(canvas => {
      // Show export button, title, and date picker
      if (exportButton) exportButton.style.display = 'block';
      if (title && !includeTitle) title.style.display = 'block';
      if (datePickers && !includeDatePicker) datePickers.forEach(picker => picker.style.display = 'block');
      setExporting(false); // End exporting process
  
      const image = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = image;
      link.download = `${month}_div.png`;
      link.click();
    });
  };
  

  const handleExportDivforRFP = (divId, month) => {
    setModalVisibleForRFP(true); // Display modal
  };
  
  // Function to handle export confirmation
  // const handleExportConfirmationForRFP = (divId) => { // Remove 'month' argument
  //   setModalVisibleForRFP(false); // Close modal
  //   setExporting(true); // Start exporting process
  //   // Get the div element directly using the provided divId
  //   const div = document.getElementById(divId);
  //   // Check if the div element exists
  //   if (!div) {
  //     console.error(`Div with id '${divId}' not found.`);
  //     return;
  //   }
  //   // Hide export button, title, and date picker if necessary
  //   const exportButton = div.querySelector('.export-icon');
  //   const title = div.querySelector('h2');
  //   const datePickers = div.querySelectorAll('.ant-picker');
  //   if (exportButton) exportButton.style.display = 'none';
  //   if (title && !includeTitle) title.style.display = 'none';
  //   if (datePickers && !includeDatePicker) datePickers.forEach(picker => picker.style.display = 'none');
  
  //   html2canvas(div).then(canvas => {
  //     // Show export button, title, and date picker
  //     if (exportButton) exportButton.style.display = 'block';
  //     if (title && !includeTitle) title.style.display = 'block';
  //     if (datePickers && !includeDatePicker) datePickers.forEach(picker => picker.style.display = 'block');
  //     setExporting(false); // End exporting process
  
  //     const image = canvas.toDataURL('image/png');
  //     const link = document.createElement('a');
  //     link.href = image;
  //     link.download = `${divId}_div.png`; // Use divId instead of month
  //     link.click();
  //   });
  // };
  
  const handleExportConfirmationForRFP = (divId, month, data) => {
    console.log(data)
    console.log("Export option selected:", exportOption);
    setModalVisibleForRFP(false); // Close modal
    
    if (exportOption === 'image') {
      console.log("Exporting as image...");
      // Handle image export
      const div = document.getElementById(divId);
      if (!div) {
        console.error(`Div with id '${divId}' not found.`);
        return;
      }
      const exportButton = div.querySelector('.export-icon');
      const title = div.querySelector('h2');
      const datePickers = div.querySelectorAll('.ant-picker');
      if (exportButton) exportButton.style.display = 'none';
      if (title && !includeTitle) title.style.display = 'none';
      if (datePickers && !includeDatePicker) datePickers.forEach(picker => picker.style.display = 'none');
      
      html2canvas(div).then(canvas => {
        if (exportButton) exportButton.style.display = 'block';
        if (title && !includeTitle) title.style.display = 'block';
        if (datePickers && !includeDatePicker) datePickers.forEach(picker => picker.style.display = 'block');
        
        const image = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = image;
        link.download = `${divId}_div.png`;
        link.click();
      });
    } else if (exportOption === 'excel') {
      console.log("Exporting as Excel...");
      // Handle Excel export
      const excelData = data.map(item => [item.date, item.firstName, item.count]);

  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Add a worksheet to the workbook
  const worksheet = XLSX.utils.aoa_to_sheet([['Date', 'BDO', 'Count'], ...excelData]);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Save the workbook as an Excel file
  XLSX.writeFile(workbook, 'data.xlsx');

    } else if (exportOption === 'word') {
        console.log("Exporting as Word...");
      
        // Create an empty HTML table
        let htmlTable = '<table>';
      
        // Add table headers
        htmlTable += '<thead><tr>';
        htmlTable += '<th>Date</th>';
        htmlTable += '<th>BDO</th>';
        htmlTable += '<th>Count</th>';
        htmlTable += '</tr></thead>';
      
        // Add table rows with data
        htmlTable += '<tbody>';
        data.forEach(item => {
          htmlTable += `<tr><td>${item.date}</td><td>${item.firstName}</td><td>${item.count}</td></tr>`;
        });
        htmlTable += '</tbody>';
      
        // Close the HTML table
        htmlTable += '</table>';
      
        // Create a Blob containing the HTML content
        const blob = new Blob(['<!DOCTYPE html><html><head><meta charset="UTF-8"></head><body>' + htmlTable + '</body></html>'], { type: 'text/html' });
      
        // Create a link element
        const link = document.createElement('a');
      
        // Set the link's attributes
        link.href = URL.createObjectURL(blob);
        link.download = 'data.docx';
      
        // Simulate a click on the link to trigger the download
        link.click();
      
      
    }
  };
  
  const handleExportDivforTPByCliet = (divId, month) => {
    setModalVisibleForTPByClient(true); // Display modal
  };
  
  // Function to handle export confirmation
  const handleExportConfirmationForTPByClient = (divId, month, data) => {
    console.log(data)
    console.log("Export option selected:", exportOption);
    setModalVisibleForTPByClient(false); // Close modal
    
    if (exportOption === 'image') {
      console.log("Exporting as image...");
      // Handle image export
      const div = document.getElementById(divId);
      if (!div) {
        console.error(`Div with id '${divId}' not found.`);
        return;
      }
      const exportButton = div.querySelector('.export-icon');
      const title = div.querySelector('h2');
      const datePickers = div.querySelectorAll('.ant-picker');
      if (exportButton) exportButton.style.display = 'none';
      if (title && !includeTitle) title.style.display = 'none';
      if (datePickers && !includeDatePicker) datePickers.forEach(picker => picker.style.display = 'none');
      
      html2canvas(div).then(canvas => {
        if (exportButton) exportButton.style.display = 'block';
        if (title && !includeTitle) title.style.display = 'block';
        if (datePickers && !includeDatePicker) datePickers.forEach(picker => picker.style.display = 'block');
        
        const image = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = image;
        link.download = `${divId}_div.png`;
        link.click();
      });
    } else if (exportOption === 'excel') {
      console.log("Exporting as Excel...");
      // Handle Excel export
      const excelData = data.map(item => [item.date, item.client, item.count]);

  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Add a worksheet to the workbook
  const worksheet = XLSX.utils.aoa_to_sheet([['Date', 'Client', 'Count'], ...excelData]);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Save the workbook as an Excel file
  XLSX.writeFile(workbook, 'data.xlsx');

    } else if (exportOption === 'word') {
        console.log("Exporting as Word...");
      
        // Create an empty HTML table
        let htmlTable = '<table>';
      
        // Add table headers
        htmlTable += '<thead><tr>';
        htmlTable += '<th>Date</th>';
        htmlTable += '<th>Client</th>';
        htmlTable += '<th>Count</th>';
        htmlTable += '</tr></thead>';
      
        // Add table rows with data
        htmlTable += '<tbody>';
        data.forEach(item => {
          htmlTable += `<tr><td>${item.date}</td><td>${item.client}</td><td>${item.count}</td></tr>`;
        });
        htmlTable += '</tbody>';
      
        // Close the HTML table
        htmlTable += '</table>';
      
        // Create a Blob containing the HTML content
        const blob = new Blob(['<!DOCTYPE html><html><head><meta charset="UTF-8"></head><body>' + htmlTable + '</body></html>'], { type: 'text/html' });
      
        // Create a link element
        const link = document.createElement('a');
      
        // Set the link's attributes
        link.href = URL.createObjectURL(blob);
        link.download = 'data.docx';
      
        // Simulate a click on the link to trigger the download
        link.click();
      
      
    }
  };
  
  const handleExportConfirmationForTPByTeam = (divId, month, data) => {
    console.log(data)
    console.log("Export option selected:", exportOption);
    setModalVisible(false); // Close modal
    
    if (exportOption === 'image') {
      console.log("Exporting as image...");
      // Handle image export
      const div = document.getElementById(divId);
      if (!div) {
        console.error(`Div with id '${divId}' not found.`);
        return;
      }
      const exportButton = div.querySelector('.export-icon');
      const title = div.querySelector('h2');
      const datePickers = div.querySelectorAll('.ant-picker');
      if (exportButton) exportButton.style.display = 'none';
      if (title && !includeTitle) title.style.display = 'none';
      if (datePickers && !includeDatePicker) datePickers.forEach(picker => picker.style.display = 'none');
      
      html2canvas(div).then(canvas => {
        if (exportButton) exportButton.style.display = 'block';
        if (title && !includeTitle) title.style.display = 'block';
        if (datePickers && !includeDatePicker) datePickers.forEach(picker => picker.style.display = 'block');
        
        const image = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = image;
        link.download = `${divId}_div.png`;
        link.click();
      });
    } else if (exportOption === 'excel') {
      console.log("Exporting as Excel...");
      // Handle Excel export
      const excelData = data.map(item => [item.date, item.teamName, item.count]);

  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Add a worksheet to the workbook
  const worksheet = XLSX.utils.aoa_to_sheet([['Date', 'Team', 'Count'], ...excelData]);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Save the workbook as an Excel file
  XLSX.writeFile(workbook, 'data.xlsx');

    } else if (exportOption === 'word') {
        console.log("Exporting as Word...");
      
        // Create an empty HTML table
        let htmlTable = '<table>';
      
        // Add table headers
        htmlTable += '<thead><tr>';
        htmlTable += '<th>Date</th>';
        htmlTable += '<th>Team</th>';
        htmlTable += '<th>Count</th>';
        htmlTable += '</tr></thead>';
      
        // Add table rows with data
        htmlTable += '<tbody>';
        data.forEach(item => {
          htmlTable += `<tr><td>${item.date}</td><td>${item.teamName}</td><td>${item.count}</td></tr>`;
        });
        htmlTable += '</tbody>';
      
        // Close the HTML table
        htmlTable += '</table>';
      
        // Create a Blob containing the HTML content
        const blob = new Blob(['<!DOCTYPE html><html><head><meta charset="UTF-8"></head><body>' + htmlTable + '</body></html>'], { type: 'text/html' });
      
        // Create a link element
        const link = document.createElement('a');
      
        // Set the link's attributes
        link.href = URL.createObjectURL(blob);
        link.download = 'data.docx';
      
        // Simulate a click on the link to trigger the download
        link.click();
      
      
    }
  };
  // Modal content
  const modalContent = (
    <div>
      <Checkbox checked={includeTitle} onChange={e => setIncludeTitle(e.target.checked)}>Include Title</Checkbox>
      <Checkbox checked={includeDatePicker} onChange={e => setIncludeDatePicker(e.target.checked)}>Include Date Picker</Checkbox>
    </div>
  );
  
  const [chartType, setChartType] = useState('Bar');
  const [chartTypeForTPByTeam, setChartTypeForTPByTeam] = useState('Bar');
  const [chartTypeForRFPByBD, setChartTypeForRFPByBD] = useState('Bar');
  const [exportOption, setExportOption] = useState("excel"); // Default to Excel


  const handleChartTypeChange = (value) => {
    setChartType(value);
  };

  const handleChartTypeChangeForTPByTeam = (value) => {
    setChartTypeForTPByTeam(value);
  };
  const handleChartTypeChangeForRFPByBD = (value) => {
    setChartTypeForRFPByBD(value);
  };
  const renderChart = (type, data) => {
    switch (type) {
      case 'Line':
        return <Line data={data} options={{ maintainAspectRatio: true }} />;
        case 'Doughnut':
          return <Doughnut data={data} options={{ maintainAspectRatio: true }} />;
  
      case 'Pie':
        return <Pie data={data} options={{ maintainAspectRatio: true }} />;
      case 'Bar':
      default:
        return <Bar data={data} options={{ maintainAspectRatio: true }} />;
    }
  };
  

  return (
    <div className="layout-content">
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        {counts &&
          countItems.map((item) => (
            <Col key={item.key} xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
              <Card bordered={false} className="criclebox">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                  <Col xs={6}>
                  {React.cloneElement(item.icon, { style: { fontSize: '34px' } })}
        </Col>
                    <Col xs={18}>
                      <span>{item.label}</span>
                      <Title level={3}>{counts[item.key]}</Title>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
      </Row>
      <Row gutter={[24, 0]}>
        {/* {timeSeriesData &&
          Object.keys(timeSeriesData).map((key) => (
            <Col key={key} xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <div id={key}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
            <h2>{key}</h2>
          </div>
          <div>
          </div>

        </div>                  <Bar
                    key={key}
                    data={memoizedGetChartData(timeSeriesData[key])}
                    options={{ maintainAspectRatio: true }}
                  />
                </div>

              </Card>
            </Col>
          ))} */}
    {allTimeSeriesData && (
        <>
          <ChartWithDatePicker
            title="CVs"
            dataKey="CVs"
            data={allTimeSeriesData.CVs}
            fetchData2={fetchData2}
            memoizedGetChartData={memoizedGetChartData}
          />
          <ChartWithDatePicker
            title="TPs"
            dataKey="TPs"
            data={allTimeSeriesData.TPs}
            fetchData2={fetchData2}
            memoizedGetChartData={memoizedGetChartData}
          />
          <ChartWithDatePicker
            title="RFPs"
            dataKey="RFPs"
            data={allTimeSeriesData.RFPs}
            fetchData2={fetchData2}
            memoizedGetChartData={memoizedGetChartData}
          />
          <ChartWithDatePicker
            title="Inception Reports"
            dataKey="IRs"
            data={allTimeSeriesData.IRs}
            fetchData2={fetchData2}
            memoizedGetChartData={memoizedGetChartData}
          />
          <ChartWithDatePicker
            title="Final Reports"
            dataKey="FRs"
            data={allTimeSeriesData.FRs}
            fetchData2={fetchData2}
            memoizedGetChartData={memoizedGetChartData}
          />
          {/* Add more ChartWithDatePicker components for other charts */}
        </>
      )}
<Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
              <Card bordered={false} title={'TP Summary'} className="criclebox h-full">
                <div>
                  <Table
      columns={columns}
      dataSource={tpSummary}
      pagination={{ pageSize: 5 }}

      rowKey="No"
      footer={() => (
        <div>
          <p> <b>Average Offering Value: </b>{averageOfferingValue}</p>
        </div>
      )}
    />
                </div>
              </Card>
            </Col>
      </Row>
      <Row gutter={[24, 0]}>
      {tpByMonth &&
        Object.keys(tpByMonth).map((month) => (
<Col key={month} xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
             <div id={`div-${month}`}>
              <Card bordered={false} className="criclebox h-full">
           
 
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <h2>{month}</h2>
                  </div>
                  <div>
                    <RangePicker onChange={handleDateChangeForTpByClient} />
                  </div>
                  <div>  <Select
          defaultValue="Bar"
          style={{ width: 80 }}
          onChange={handleChartTypeChange}
        >
          <Option value="Bar">Bar</Option>
          <Option value="Line">Line</Option>
        
        </Select></div>
                  <ExportOutlined
                    className="export-icon"
                    onClick={() => handleExportDivforTPByCliet(`div-${month}`, month)}
                  />
                </div>
                {renderChart(
                  chartType,
                  memoizedGetChartDataForTPByClient(groupDataByMonth(tpByMonth[month]))
                )}
                {/* <Modal
                  title="Export Options"
                  visible={modalVisibleForTPByClient}
                  onCancel={() => setModalVisibleForTPByClient(false)}
                  onOk={() => handleExportConfirmationForTPByClient(`div-${month}`, month)}
                >
                  {modalContent}
                </Modal> */}
                <Modal
            title="Export Options"
            visible={modalVisibleForTPByClient}
            onCancel={() => setModalVisibleForTPByClient(false)}
            onOk={() => handleExportConfirmationForTPByClient(`div-${month}`, month,              tpByMonth[month] 
)}
          >
            <Checkbox checked={includeTitle} onChange={(e) => setIncludeTitle(e.target.checked)}>
              Include Title
            </Checkbox>
            <Checkbox checked={includeDatePicker} onChange={(e) => setIncludeDatePicker(e.target.checked)}>
              Include Date Picker
            </Checkbox>
            <Radio.Group value={exportOption} onChange={(e) => setExportOption(e.target.value)}>
  <Radio value="excel">Export to Excel</Radio>
  <Radio value="word">Export to Word</Radio>
  <Radio value="image">Export as Image</Radio>
</Radio.Group>

          </Modal>
              </Card>
            </div>
          </Col>
        ))}
        </Row>
       <Row gutter={[24, 0]}> 
   
{tpByTeam &&
      Object.keys(tpByTeam).map((month) => (
        <Col key={month} xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
          <Card bordered={false} className="criclebox h-full" id={`div-${month}`}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <h2>{month}</h2>
              </div>
              <div>
                <DatePicker.RangePicker onChange={handleDateChange} />
              </div>
              <div>  <Select
          defaultValue="Bar"
          style={{ width: 80 }}
          onChange={handleChartTypeChangeForTPByTeam}
        >
          <Option value="Bar">Bar</Option>
          <Option value="Line">Line</Option>
         
        </Select></div>
          <ExportOutlined className="export-icon" onClick={() => handleExportDiv(`div-${month}`, month)} /> {/* Icon instead of button */}
            </div>
         
            {renderChart(
                  chartTypeForTPByTeam,
                  memoizedGetChartDataForTPByTeam(groupDataByMonthTeam(tpByTeam[month]))
                )}
              {/* <Modal
      title="Export Options"
      visible={modalVisible}
      onCancel={() => setModalVisible(false)}
      onOk={() => handleExportConfirmation(`div-${month}`, month)}
    >
      {modalContent}
    </Modal> */}

<Modal
            title="Export Options"
            visible={modalVisible}
            onCancel={() => setModalVisible(false)}
            onOk={() => handleExportConfirmationForTPByTeam(`div-${month}`, month,              tpByTeam[month] 
)}
          >
            <Checkbox checked={includeTitle} onChange={(e) => setIncludeTitle(e.target.checked)}>
              Include Title
            </Checkbox>
            <Checkbox checked={includeDatePicker} onChange={(e) => setIncludeDatePicker(e.target.checked)}>
              Include Date Picker
            </Checkbox>
            <Radio.Group value={exportOption} onChange={(e) => setExportOption(e.target.value)}>
  <Radio value="excel">Export to Excel</Radio>
  <Radio value="word">Export to Word</Radio>
  <Radio value="image">Export as Image</Radio>
</Radio.Group>

          </Modal>
          </Card>
        </Col>
        
      ))}
      
  
 



{rfpByTeamMember &&
  Object.keys(rfpByTeamMember).map((month) => (
    <Col key={month} xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
      <div id='div-RFPs By BDO'>

      <Card bordered={false} className="criclebox h-full">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <h2>{month}</h2>
          </div>
          <div>
            <DatePicker.RangePicker onChange={handleDateChangeRFPByTeamMember} />
          </div>
         
              <div>  <Select
          defaultValue="Bar"
          style={{ width: 80 }}
          onChange={handleChartTypeChangeForRFPByBD}
        >
          <Option value="Bar">Bar</Option>
          <Option value="Line">Line</Option>
       

        </Select></div>
          <ExportOutlined className="export-icon" onClick={() => handleExportDivforRFP(`div-${month}`, month)} />
        </div>
     

          {renderChart(
            chartTypeForRFPByBD,
            memoizedGetChartDataForRFPByTeamMember(groupDataByMonthTeamMember(rfpByTeamMember[month]))
          )}
     

{/* <Modal
      title="Export Options"
      visible={modalVisibleForRFP}
      onCancel={() => setModalVisibleForRFP(false)}
      onOk={() => handleExportConfirmationForRFP(`div-${month}`, month)}
    >
      {modalContent}
    </Modal> */}

    <Modal
            title="Export Options"
            visible={modalVisibleForRFP}
            onCancel={() => setModalVisibleForRFP(false)}
            onOk={() => handleExportConfirmationForRFP(`div-${month}`, month,              rfpByTeamMember[month] 
)}
          >
            <Checkbox checked={includeTitle} onChange={(e) => setIncludeTitle(e.target.checked)}>
              Include Title
            </Checkbox>
            <Checkbox checked={includeDatePicker} onChange={(e) => setIncludeDatePicker(e.target.checked)}>
              Include Date Picker
            </Checkbox>
            <Radio.Group value={exportOption} onChange={(e) => setExportOption(e.target.value)}>
  <Radio value="excel">Export to Excel</Radio>
  <Radio value="word">Export to Word</Radio>
  <Radio value="image">Export as Image</Radio>
</Radio.Group>

          </Modal>
      </Card>
      </div>
    </Col>
  ))}





</Row>
    </div>
  );
}

export default Home;
