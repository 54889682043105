
// export default ExperienceList;
import React, { useState, useEffect } from 'react';
import { Table, message, Typography, Tooltip, Button, InputNumber } from 'antd';
import { NavLink } from "react-router-dom";
import { InfoCircleOutlined } from '@ant-design/icons';
import useFetchWithToken from '../../services/api';
import { useHistory } from 'react-router-dom';

const { Text } = Typography;

const ExperienceList = ({ reoiId }) => {
  const [cvs, setCvs] = useState([]);
  const { token } = useFetchWithToken('reois');
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [numItems, setNumItems] = useState(5); // Default value is 5

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from the API
        const response = await fetch(`https://kmsbe.frontieri.com/kmsApi/reoisFirmExperiences/${reoiId}?numItems=${numItems}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setCvs(data);
        setLoading(false);
        setPagination(prevState => ({ ...prevState, total: data.length }));
      } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          // Redirect to login page if status code is 401 or 403
          history.push('/login');
          message.error("Session expired. Please login again.");
        } else {
          message.error("Unable to load data");
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [reoiId, numItems, token, history]);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };
  const getColor = (score) => {
    if (score < 30) return 'red';
    if (score >= 30 && score < 50) return 'gray';
    if (score >= 50 && score < 70) return 'blue';
    return 'green';
  };


  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    {
      title: 'Similarity (%)',
      dataIndex: 'similarityScore',
      key: 'similarityScore',
      width: '20%',

      render: (score) => (
        <span style={{ color: getColor(score * 100) }}>
          {(score * 100).toFixed(2)}
        </span>
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text) => <Tooltip title={text}>{text.length > 20 ? `${text.substring(0, 10)}...` : text}</Tooltip>
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      render: (text) => <Tooltip title={text}>{text.length > 50 ? `${text.substring(0, 10)}...` : text}</Tooltip>
    },
    { title: 'Sector', dataIndex: 'sector', key: 'sector' },
    // { title: 'Worth', dataIndex: 'worth', key: 'worth' },
    // {
    //   title: 'Duration',
    //   dataIndex: 'duration',
    //   key: 'duration',
    //   render: (text) => `${text} months`
    // },
    // { title: 'Project Type', dataIndex: 'projectType', key: 'projectType' },
    // {
    //   title: 'Summary',
    //   dataIndex: 'summary',
    //   key: 'summary',
    //   render: (text) => <Tooltip title={text}>{text.length > 50 ? `${text.substring(0, 10)}...` : text}</Tooltip>
    // },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button type="link">
          <NavLink to={`/firmExperienceDetails/${record.id}`} style={{ color: 'green' }}>
            <InfoCircleOutlined /> &nbsp;Details
          </NavLink>
        </Button>
      ),
    },
  ];

  return (
    <>
      <InputNumber
        min={1}
        max={100}
        defaultValue={5}
        onChange={value => setNumItems(value)}
        style={{ marginBottom: 16 }}
      />
      <Table
        columns={columns}
        dataSource={cvs}
        rowKey="id"
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default ExperienceList;
