import React, { useState, useEffect } from 'react';
import { Button, Table, Modal, Form, Select, Input, message, Row, Col, Tooltip, Radio } from 'antd';
import useFetchWithToken from '../../services/api';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const styles = {
    buttonBg: {
      backgroundColor: '#f0f0f0',
    },
    buttonCheckedBg: {
      backgroundColor: '#1890ff',
    },
    buttonCheckedBgRed: {
      backgroundColor: 'darkred',
    },
    buttonCheckedBgGreen: {
      backgroundColor: 'darkgreen',
      
    },
    buttonCheckedBgYellow: {
      backgroundColor: 'darkorange',
    },
    buttonCheckedBgDisabled: {
      backgroundColor: '#d9d9d9',
    },
    buttonCheckedColorDisabled: {
      color: '#bfbfbf',
    },
    buttonColor: {
      color: '#000000',
    },
    buttonColorGreen: {
      color: 'darkgreen',
    },
    buttonColorYellow: {
      color: 'darkorange',
    },
    buttonColorRed: {
      color: 'darkred',
    },
    buttonPaddingInline: {
      paddingInline: '16px',
    },
    buttonSolidCheckedActiveBg: {
      backgroundColor: '#40a9ff',
    },
    buttonSolidCheckedBg: {
      backgroundColor: '#1890ff',
    },
    buttonSolidCheckedColor: {
      color: '#ffffff',
    },
    buttonSolidCheckedHoverBg: {
      backgroundColor: '#69c0ff',
    },
    dotColorDisabled: {
      color: '#d9d9d9',
    },
    dotColorGreen: {
      color: 'green',
    },
    dotColorYellow: {
      color: 'darkorange',
    },
    dotColorRed: {
      color: 'darkred',
    },
    dotSize: {
      width: '20px',
      height: '20px',
    },
  };
  
const { Option } = Select;

const CvProjectsWithList = () => {
  const [cvs, setCVs] = useState([]);
  const [selectedCV, setSelectedCV] = useState(null);
  const [projects, setProjects] = useState([]);
  const [cvProjects, setCVProjects] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [points, setPoints] = useState('');
  const [position, setPosition] = useState('');
  const history = useHistory();

  const { data: allProjects, loading, error, token } = useFetchWithToken('projects');
  const { postData } = useFetchWithToken('cvProjects2');

  useEffect(() => {
    const fetchCVs = async () => {
      try {
        const response = await axios.get('https://kmsbe.frontieri.com/kmsApi/hrcvs', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCVs(response.data);
      } catch (error) {
        message.error('Unable to load CVs!');
      }
    };

    fetchCVs();
  }, [token]);

  useEffect(() => {
    if (allProjects) {
      setProjects(allProjects);
    }
  }, [allProjects]);

  useEffect(() => {
    const fetchCVProjects = async () => {
      if (selectedCV) {
        try {
          const response = await axios.get(`https://kmsbe.frontieri.com/kmsApi/hrcvProjects/${selectedCV}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setCVProjects(response.data);
        } catch (error) {
          message.error('Unable to load CV Projects!');
        }
      }
    };

    fetchCVProjects();
  }, [selectedCV, token]);

  const handleAddProject = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleOk = () => {
    setModalVisible(false);
  };

  const handleProjectChange = (value) => {
    setSelectedProject(value);
  };

  const handlePointsChange = (e) => {
    setPoints(e.target.value);
  };

  const handlePositionChange = (e) => {
    setPosition(e.target.value);
  };

  const handleCVChange = (value) => {
    setSelectedCV(value);
  };

  const onFinish = async (values) => {
    try {
      const knowledgeOfWork = parseInt(values.knowledgeOfWork);
      const qualityOfWork = parseInt(values.qualityOfWork);
      const meetingDeadline = parseInt(values.meetingDeadline);
      const planning = parseInt(values.planning);
      const average = (knowledgeOfWork + qualityOfWork + meetingDeadline + planning) / 4;

      const data = {
        cvId: selectedCV,
        projectId: values.project,
        points: average,
        position: values.position,
        qualityOfWork: values.qualityOfWork,
        planning: values.planning,
        knowledgeOfWork: values.knowledgeOfWork,
        meetingDeadline: values.meetingDeadline,
      };

      await axios.post('https://kmsbe.frontieri.com/kmsApi/hrcvProjects2', data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      message.success('Project associated successfully!');
      setModalVisible(false);
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        history.push('/login');
        message.error('Session expired. Please login again.');
      } else {
        message.error('Unable to load data!');
      }
    }
  };

  const columns = [
    // ... (your columns definition here)
  ];

  return (
    <div>
     
      {/* <Table dataSource={cvProjects} columns={columns} loading={loading} rowKey="ProjectId" /> */}
      {/* <Modal
        title="Add Project Evaluation"
        visible={true}
        footer={false}
        onCancel={handleCancel}
        onOk={handleOk}
        width={600}
      > */}
        <Form onFinish={onFinish}>

        {/* <Row gutter={[16, 16]}>
            <Col span={12}>
        <Select placeholder="Select CV" onChange={handleCVChange} style={{ width: '200px', marginBottom: '16px' }}>
        {cvs.map((cv) => (
          <Option key={cv.id} value={cv.id}>
            {cv.name}
          </Option>
        ))}
      </Select>

</Col></Row> */}
         <Row gutter={[16, 16]}>
  <Col span={8}>
    <Select 
      placeholder="Select Expert" 
      onChange={handleCVChange} 
      style={{ width: '200px', marginBottom: '16px' }}
    >
      {cvs.map((cv) => (
        <Option key={cv.id} value={cv.id}>
          {cv.expertName}
        </Option>
      ))}
    </Select>
  </Col>
  <Col span={8}>
    <Form.Item
      label="Project"
      name="project"
      rules={[{ required: true, message: 'Please select a project' }]}
    >
      <Select 
        style={{ width: '100%' }} 
        onChange={handleProjectChange}
      >
        {projects.map((project) => (
          <Option key={project.id} value={project.id}>
            {project.title}
          </Option>
        ))}
      </Select>
    </Form.Item>
  </Col>
  <Col span={8}>
    <Form.Item
      label="Position"
      name="position"
      rules={[{ required: true, message: 'Please enter a Position' }]}
    >
      <Input 
        onChange={handlePositionChange} 
        value={position} 
      />
    </Form.Item>
  </Col>
</Row>

          <Row>
            <Table
              dataSource={[
                { key: '1', label: 'Knowledge of Work', name: 'knowledgeOfWork' },
                { key: '2', label: 'Quality of Work', name: 'qualityOfWork' },
                { key: '3', label: 'Meeting Deadline', name: 'meetingDeadline' },
                { key: '4', label: 'Affordability', name: 'planning' },
              ]}
              columns={[
                {
                  title: 'Criteria',
                  dataIndex: 'label',
                  key: 'label',
                  width: '40%',
                },
                {
                  title: 'Excellent',
                  key: 'green',
                  width: '20%',
                  render: (_, record) => (
                    <Form.Item name={record.name} rules={[{ required: true }]}>
                      <Radio.Group>
                        <Radio value={5} style={{ ...styles.buttonCheckedBgGreen, ...styles.dotColorGreen }}></Radio>
                      </Radio.Group>
                    </Form.Item>
                  ),
                },
                {
                  title: 'Fair',
                  key: 'yellow',
                  width: '300px',
                  render: (_, record) => (
                    <Form.Item name={record.name} rules={[{ required: true }]}>
                      <Radio.Group>
                        <Radio value={3} style={{ ...styles.buttonCheckedBgYellow, ...styles.dotColorYellow }}></Radio>
                      </Radio.Group>
                    </Form.Item>
                  ),
                },
                {
                  title: 'Poor',
                  key: 'red',
                  width: '20%',
                  render: (_, record) => (
                    <Form.Item name={record.name} rules={[{ required: true }]}>
                      <Radio.Group>
                        <Radio value={1} style={{ ...styles.buttonCheckedBgRed, ...styles.dotColorRed }}></Radio>
                      </Radio.Group>
                    </Form.Item>
                  ),
                },
              ]}
              pagination={false}
            />
          </Row>
          <Form.Item>
            <Button style={{ position: 'absolute', right: '0' }} type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      {/* </Modal> */}
    </div>
  );
};

export default CvProjectsWithList;
