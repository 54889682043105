import React, { useState, useEffect } from 'react';
import { Card, Row, Col, message, Input, Button, Table, Modal, Upload, Checkbox, Dropdown, Tooltip, Spin, Drawer, notification, Tabs, Descriptions, Popconfirm, DatePicker } from 'antd';
import { PlusOutlined, SearchOutlined, EditOutlined, DeleteOutlined, InfoCircleOutlined, ToTopOutlined, DownOutlined } from '@ant-design/icons';
import ProjectForm from './projectForm'; // Import the ProjectForm component
import useFetchWithToken from '../../services/api';
import { NavLink } from "react-router-dom";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import axios from 'axios';
import moment from 'moment';

const { Search } = Input;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const FirmExperience = () => {
  const [projectModalVisible, setProjectModalVisible] = useState(false);
  const [formData, setFormData] = useState({});
  const { data: projectData, loading, error, refetchData } = useFetchWithToken('projects');
  const { data: cvData, postFormData } = useFetchWithToken("cvs");
  const [uploading, setUploading] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]); // Add state for selected columns
  const defaultSelectedColumns = ['title', 'client', 'worth', 'projectType', 'summary', 'startDate', 'endDate', 'duration']; // Default selected columns
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [generalDetailsVisible, setGeneralDetailsVisible] = useState(false);
  const [selectedRowDetails, setSelectedRowDetails] = useState(null);
  const [editableFields, setEditableFields] = useState({});
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [dateRange, setDateRange] = useState([]);

  const getUniqueValues = (data, key) => {
    if (!data) return [];
    return [...new Set(data.map(item => item[key]))].map(value => ({ text: value, value }));
  };

  useEffect(() => {
    setSelectedColumns(defaultSelectedColumns);
  }, []);

  useEffect(() => {
    if (projectData) {
      const processedData = projectData.map(item => {
        if (item.duration) {
          const durationParts = item.duration.split('Project Start Date-');
          if (durationParts.length > 1) {
            const dateParts = durationParts[1].split('Project End Date-');
            if (dateParts.length > 1) {
              const startDate = dateParts[0] ? dateParts[0].trim() : '';
              const endDate = dateParts[1] ? dateParts[1].trim() : '';
              const startMoment = startDate ? moment(startDate, 'MMM/ DD') : null;
              const endMoment = endDate ? moment(endDate, 'MMM/ DD') : null;
              const duration = startMoment && endMoment ? endMoment.diff(startMoment, 'months') + ' months' : '';
              return {
                ...item,
                startDate: startMoment ? startMoment.format('MMM YYYY') : '',
                endDate: endMoment ? endMoment.format('MMM YYYY') : '',
                duration,
              };
            }
          }
        }
        // If duration is not defined or not in expected format, return item with empty date fields
        return { ...item, startDate: '', endDate: '', duration: '' };
      });
      setFilteredData(processedData); // Set filtered data initially
      // Update filter options when data changes
      projectColumns[2].filters = getUniqueValues(processedData, 'client');
      projectColumns[3].filters = getUniqueValues(processedData, 'sector');
      projectColumns[6].filters = getUniqueValues(processedData, 'projectType');
    }
  }, [projectData]);

  const handleAddProject = () => {
    setFormData({});
    setProjectModalVisible(true);
  };

  const highlightMatchedText = (text, query) => {
    if (!text || !query || query.trim() === '') return text;

    // Ensure text is a string
    if (typeof text !== 'string') return text;

    const index = text.toLowerCase().indexOf(query.toLowerCase());
    if (index === -1) return text;

    const maxLength = 20; // Adjust the number of characters to display before and after the highlighted text
    const startIndex = Math.max(0, index - maxLength);
    const endIndex = Math.min(text.length, index + query.length + maxLength);

    const prefix = startIndex > 0 ? '...' : '';
    const suffix = endIndex < text.length ? '...' : '';

    const highlightedText = text.substring(startIndex, endIndex)
      .replace(new RegExp(query, 'gi'), (match) => `<span style="background-color: yellow">${match}</span>`);

    return (
      <span dangerouslySetInnerHTML={{ __html: prefix + highlightedText + suffix }} />
    );
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          icon={<SearchOutlined />}
          size="small"
          onClick={() => confirm()}
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button size="small" onClick={() => clearFilters()} style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      const recordValue = record[dataIndex];
      return recordValue ? recordValue.toString().toLowerCase().includes(value.toLowerCase()) : false;
    }
  });

  const getColumnDatePickerProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <RangePicker
          value={selectedKeys[0]}
          onChange={(dates) => setSelectedKeys(dates ? [dates] : [])}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (!value || value.length === 0) return true;
      const recordDate = moment(record[dataIndex], 'MMM YYYY');
      return recordDate.isBetween(value[0], value[1], 'days', '[]');
    },
    render: (text) => text,
  });

  const CollapsibleText = ({ text, maxLength = 200 }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => setIsExpanded(!isExpanded);

    const shouldTruncate = text.length > maxLength;

    return (
      <div className={`collapsible-text ${isExpanded ? 'expanded' : ''}`}>
        {isExpanded ? text : (shouldTruncate ? text.substring(0, maxLength) + '...' : text)}
        {shouldTruncate && (
          <span className="toggle" onClick={toggleExpansion}>
            {/* {isExpanded ? 'Show less' : 'Show more'} */}
          </span>
        )}
      </div>
    );
  };

  const handleUpload = async ({ file }) => {
    try {
      setUploading(true);
      const formData = new FormData();
      formData.append("file", file);

      // Use the postFormData function to upload the file
      await postFormData(formData, 'uploadFirmExperience');

      // Handle successful upload
      message.success(`${file.name} uploaded successfully`);
      setUploading(false);
    } catch (error) {
      // Handle upload error
      console.log(error);
      message.success(`${file.name} uploaded successfully`);
      setUploading(false);
    } finally {
      setUploading(false);
    }
  };

  const handleEditProject = (record) => {
    setFormData(record);
    setProjectModalVisible(true);
  };

  const handleDeleteProject = async (projectId) => {
    try {
      const token = await localStorage.getItem('token');
      await axios.delete(`https://kmsbe.frontieri.com/kmsApi/projects/${projectId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      notification.success({
        message: 'Success',
        description: 'Project deleted successfully.',
      });
      refetchData(); // Refetch data after deletion
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to delete project.',
      });
    }
  };

  const filterAndHighlightData = (data, query) => {
    return data.map(item => {
      const highlightedItem = { ...item };
      if (query.trim() !== '') {
        highlightedItem.title = highlightMatchedText(item.title, query);
        highlightedItem.projectType = highlightMatchedText(item.projectType, query);
        highlightedItem.client = highlightMatchedText(item.client, query);
        highlightedItem.worth = highlightMatchedText(item.worth, query);
        highlightedItem.summary = highlightMatchedText(item.summary, query);
      }
      return highlightedItem;
    });
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value.trim() === '') {
      setFilteredData(projectData);
    } else {
      const filtered = filterAndHighlightData(projectData, e.target.value);
      setFilteredData(filtered);
    }
  };

  const handleRowClick = (record) => {
    setSelectedRowDetails(record);
    setGeneralDetailsVisible(true);
  };

  const handleTitleClick = (record) => {
    setSelectedRowDetails(record);
    setDetailsVisible(true);
  };

  const handleFieldChange = (field, value) => {
    setEditableFields({ ...editableFields, [field]: value });
  };

  const handleDateRangeChange = (dates, dateStrings) => {
    setDateRange(dates);
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      const filtered = projectData.filter(item => {
        const projectStartDate = moment(item.startDate, 'MMM YYYY');
        const projectEndDate = moment(item.endDate, 'MMM YYYY');
        return projectStartDate.isBetween(start, end, 'days', '[]') || projectEndDate.isBetween(start, end, 'days', '[]');
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(projectData);
    }
  };

  const renderTeamMembers = (teamMembers) => {
    return teamMembers.split(',').map((member, index) => {
      const [name, internalOrExternal, position] = member.split('-').map(item => item.trim());
      return { key: index, name, internalOrExternal, position };
    });
  };

  // Team members and associated members columns
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Internal/External',
      dataIndex: 'internalOrExternal',
      key: 'internalOrExternal',
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
    },
  ];
  const projectColumns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '25%',
      ...getColumnSearchProps('title'),
      render: (text, record) => (
        <div className="wrap-text" onClick={(e) => { e.stopPropagation(); handleTitleClick(record); }}>
          <CollapsibleText text={text} maxLength={200} />
        </div>
      ),
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      width: '15%',
      sorter: (a, b) => a.client.localeCompare(b.client),
      filters: getUniqueValues(projectData, 'client'),
      onFilter: (value, record) => record.client.includes(value),
      render: (text, record) => (
        <div className="wrap-text" onClick={() => handleRowClick(record)}>
          <CollapsibleText text={text} maxLength={200} />
        </div>
      ),
    },
    {
      title: 'Sector',
      dataIndex: 'sector',
      key: 'sector',
      width: '10%',
      sorter: (a, b) => a.sector.localeCompare(b.sector),
      filters: getUniqueValues(projectData, 'sector'),
      onFilter: (value, record) => record.sector.includes(value),
      render: (text, record) => (
        <div className="wrap-text" onClick={() => handleRowClick(record)}>
          <CollapsibleText text={text} maxLength={200} />
        </div>
      ),
    },
    {
      title: 'Worth',
      dataIndex: 'worth',
      key: 'worth',
      width: '5%',
      ...getColumnSearchProps('worth'),
      render: (text, record) => (
        <div className="wrap-text" onClick={() => handleRowClick(record)}>
          <CollapsibleText text={text} maxLength={200} />
        </div>
      ),
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      width: '5%',
      ...getColumnSearchProps('duration'),
      render: (text, record) => (
        <div className="wrap-text" onClick={() => handleRowClick(record)}>
          <CollapsibleText text={text} maxLength={200} />
        </div>
      ),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      width: '5%',
      ...getColumnDatePickerProps('startDate'),
      render: (text) => moment(text, 'MMM YYYY').format('MMM YYYY'),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      width: '5%',
      ...getColumnDatePickerProps('endDate'),
      render: (text) => moment(text, 'MMM YYYY').format('MMM YYYY'),
    },
    {
      title: 'Project Type',
      dataIndex: 'projectType',
      key: 'projectType',
      width: '10%',
      sorter: (a, b) => a.projectType.localeCompare(b.projectType),
      filters: getUniqueValues(projectData, 'projectType'),
      onFilter: (value, record) => record.projectType.includes(value),
      render: (text, record) => (
        <div className="wrap-text" onClick={() => handleRowClick(record)}>
          <CollapsibleText text={text} maxLength={200} />
        </div>
      ),
    },
    // {
    //   title: 'Summary',
    //   dataIndex: 'summary',
    //   key: 'summary',
    //   width: '40%',
    //   ...getColumnSearchProps('summary'),
    //   render: (text, record) => (
    //     <div className="wrap-text" onClick={() => handleRowClick(record)}>
    //       <CollapsibleText text={text} maxLength={170} />
    //     </div>
    //   ),
    // },
  ];

  const ColumnSelector = () => (
    <Checkbox.Group
      options={projectColumns.map(column => ({ label: column.title, value: column.key }))}
      value={selectedColumns}
      onChange={(selected) => setSelectedColumns(selected)}
    />
  );

  const DynamicTable = ({ data }) => {
    const filteredColumns = projectColumns.filter(column => selectedColumns.includes(column.key));
    const [pageSize, setPageSize] = useState(10);
    const [current, setCurrent] = useState(1);

    return (
      <>
        <Dropdown overlay={<ColumnSelector />} trigger={["click"]}>
          <Button>Select Columns <DownOutlined /></Button>
        </Dropdown>
        <Table columns={filteredColumns} dataSource={data} pagination={{
          current: current,
          pageSize: pageSize,
          onChange: (page, pageSize) => {
            setCurrent(page);
            setPageSize(pageSize);
          },
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '50'],
        }} />
      </>
    );
  };

  const saveChanges = async () => {
    try {
      const token = await localStorage.getItem('token');
      const updatedData = { ...selectedRowDetails, ...editableFields };
      await axios.put(`https://kmsbe.frontieri.com/kmsApi/projects/${selectedRowDetails.id}`, updatedData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      notification.success({
        message: 'Success',
        description: 'Data updated successfully.',
      });
      setEditableFields({}); // Clear the editable fields
      setSelectedRowDetails({ ...selectedRowDetails, ...editableFields }); // Update the selectedRow with new data
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to update data.',
      });
    }
  };

  return (
    <div>
      <Card>
        <Row gutter={[24, 0]}>
          <Col span={12}>
          </Col>
          <Col span={12}>
            <Search
              placeholder="Search"
              width={10}
              allowClear
              enterButton={<SearchOutlined style={{ fontSize: '25px' }} />}
              size="large"
              onChange={handleSearchInputChange}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            {error && <div>Error: {error}</div>}
            {loading ? (
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Spin size="large" />
              </div>
            ) : (
              <div style={{ overflowX: 'auto' }}>
                <div style={{ marginBottom: '16px' }}>
                  <RangePicker onChange={handleDateRangeChange} />
                </div>
                {filteredData.length > 0 && (
                  <DynamicTable data={filteredData} pagination={{ pageSize: 5 }} />
                )}
                {filteredData.length === 0 && (
                  <DynamicTable data={projectData} pagination={{ pageSize: 5 }} />
                )}
              </div>)}
          </Col>
        </Row>
      </Card>
      <Card bordered={false}>
        <div className="uploadfile pb-15 shadow-none">
          <Upload
            name="file"
            customRequest={handleUpload}
            beforeUpload={(file) => {
              const isExcel = file.type === "application/vnd.ms-excel" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              if (!isExcel) {
                message.error("You can only upload Excel files!");
              }
              return isExcel;
            }}
          >
            <Button type="dashed" className="ant-full-box" icon={<ToTopOutlined />} loading={uploading}>
              Click to Upload
            </Button>
          </Upload>
        </div>
      </Card>

      <Modal
        title={formData.id ? 'Edit Firm Experience' : 'Add Firm Experience'}
        visible={projectModalVisible}
        onCancel={() => setProjectModalVisible(false)}
        footer={null}
        width={800} // Adjust the width here as needed
      >
        <ProjectForm formData={formData} setFormData={setFormData} closeModal={() => setProjectModalVisible(false)} refetchData={refetchData} />
      </Modal>

      <Drawer
        title={selectedRowDetails ? `Details of ${selectedRowDetails.title}` : 'Details'}
        width={640}
        placement="right"
        onClose={() => setDetailsVisible(false)}
        visible={detailsVisible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Button onClick={saveChanges} type="primary">Save</Button>
            </div>
          </div>
        }
      >
        {loadingDetails ? (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Spin size="large" />
          </div>
        ) : (
          selectedRowDetails && (
            <Tabs defaultActiveKey="1">
              <TabPane tab="Details" key="1">
                <Card
                  style={{ height: "800px", overflow: "auto" }}
                  headStyle={{ position: 'sticky', top: '0', zIndex: '1', background: '#fff' }}
                >
                  <Descriptions style={{ marginTop: '60px' }}>
                    <Descriptions.Item label="Title" span={3}>
                      <span
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => handleFieldChange('title', e.target.innerText)}
                        onDoubleClick={(e) => e.target.focus()}
                      >
                        {editableFields.title || selectedRowDetails.title}
                      </span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Client" span={3}>
                      <span
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => handleFieldChange('client', e.target.innerText)}
                        onDoubleClick={(e) => e.target.focus()}
                      >
                        {editableFields.client || selectedRowDetails.client}
                      </span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Worth" span={3}>
                      <span
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => handleFieldChange('worth', e.target.innerText)}
                        onDoubleClick={(e) => e.target.focus()}
                      >
                        {editableFields.worth || selectedRowDetails.worth}
                      </span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Duration" span={3}>
                      <span
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => handleFieldChange('duration', e.target.innerText)}
                        onDoubleClick={(e) => e.target.focus()}
                      >
                        {editableFields.duration || selectedRowDetails.duration}
                      </span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Project Type" span={3}>
                      <span
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => handleFieldChange('projectType', e.target.innerText)}
                        onDoubleClick={(e) => e.target.focus()}
                      >
                        {editableFields.projectType || selectedRowDetails.projectType}
                      </span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Summary" span={3}>
                      <span
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => handleFieldChange('summary', e.target.innerText)}
                        onDoubleClick={(e) => e.target.focus()}
                      >
                        {editableFields.summary || selectedRowDetails.summary}
                      </span>
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </TabPane>
              <TabPane tab="File Preview" key="2">
                <Card bordered={false} className="header-solid h-full">
                  <h4>Firm Experience Preview:</h4>
                  <div style={{ width: "100%", height: "800px" }}>
                    <DocViewer
                      pluginRenderers={DocViewerRenderers}
                      documents={selectedRowDetails.file ? [{ uri: `https://kmsbe.frontieri.com/kmsApi/${selectedRowDetails.file}` }] : []}
                      config={{
                        header: {
                          disableHeader: false,
                          disableFileName: true,
                          retainURLParams: false
                        }
                      }}
                      style={{ height: 800 }}
                    />
                  </div>
                </Card>
              </TabPane>
            </Tabs>
          )
        )}
      </Drawer>

      <Drawer
        title={selectedRowDetails ? `General Details of ${selectedRowDetails.title}` : 'General Details'}
        width={640}
        placement="right"
        onClose={() => setGeneralDetailsVisible(false)}
        visible={generalDetailsVisible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Button onClick={saveChanges} type="primary">Save</Button>
            </div>
          </div>
        }
      >
        {loadingDetails ? (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Spin size="large" />
          </div>
        ) : (
          selectedRowDetails && (
            <Tabs defaultActiveKey="1">
              <TabPane tab="Summary" key="1">
                <Card bordered={false} className="header-solid h-full">
                  <Descriptions>
                    <Descriptions.Item label="Summary" span={3}>
                      <span
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => handleFieldChange('summary', e.target.innerText)}
                        onDoubleClick={(e) => e.target.focus()}
                      >
                        {editableFields.summary || selectedRowDetails.summary}
                      </span>
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </TabPane>
              <TabPane tab="Team Members" key="2">
                <Card>
                  <Table
                    dataSource={renderTeamMembers(selectedRowDetails.teamMembers)}
                    columns={columns}
                    pagination={false}
                    rowKey="key"
                  />
                </Card>
              </TabPane>
            </Tabs>
          )
        )}
      </Drawer>
    </div>
  );
};

export default FirmExperience;
