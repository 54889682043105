import React, { useState, useEffect } from "react";
import { Row, Col, Card, Upload, message, Descriptions, Button, Tabs } from "antd";
import { useParams } from "react-router-dom";
import { ToTopOutlined } from '@ant-design/icons';
import useFetchWithToken from '../../services/api'; // Import the useFetchWithToken hook
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import CvList from "./cvs";
import ExperienceList from "./experience";

const { TabPane } = Tabs;

function REOIDetail() {
  const [fileList, setFileList] = useState([]);
  const { id } = useParams();
  const { data: reoiData, loading, postFormData } = useFetchWithToken(`reois/${id}`); // Fetch REOI details using useFetchWithToken hook
  const [filePath, setFilePath] = useState("");
  const [docs, setDocs] = useState([]);

  const beforeUpload = (file) => {
    setFileList([file]);
    return false; // Returning false prevents default upload behavior
  };

  useEffect(() => {
    if (reoiData) {
      setDocs([{ uri: `https://kmsbe.frontieri.com/kmsApi/${reoiData.file}` }]);
    }
  }, [reoiData]);

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", fileList[0]);

    try {
      await postFormData(formData, `uploadREOI/${id}`); // Upload REOI using postFormData function from useFetchWithToken hook
      message.success("File uploaded successfully!");
      setFileList([]);
    } catch (error) {
      message.error("File upload failed!");
      setFileList([]);
    }
  };

  if (loading || !reoiData || !docs.length) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/* Header with Tabs */}
      <div style={{ padding: '10px 20px', background: '#fff', borderBottom: '1px solid #f0f0f0' }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="General Information" key="1" />
          {/* <TabPane tab="Available Experts" key="2" /> */}
          {/* <TabPane tab="Firm Experiences" key="6" />
          <TabPane tab="Technical Proposal" key="3" />
          <TabPane tab="Inception Report" key="4" />
          <TabPane tab="Final Report" key="5" /> */}
        </Tabs>
      </div>

      {/* Main Content Area with Sidebar */}
      <div style={{ display: 'flex', marginTop: '20px' }}>
        <div style={{ flex: 6, padding: '0 20px' }}> {/* Adjusted from 4 to 6 */}
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Card type="primary" bordered={false} title={<h6 className="font-semibold m-0">REOI Information</h6>} bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}>
                <Descriptions style={{ marginTop: '20px' }}>
                  <Descriptions.Item label="Title" span={3}>{reoiData.title}</Descriptions.Item>
                  <Descriptions.Item label="Client" span={3}>{reoiData.client}</Descriptions.Item>
                  <Descriptions.Item label="Country" span={3}>{reoiData.country}</Descriptions.Item>
                  <Descriptions.Item label="Issued On" span={3}>{reoiData.issuedOn}</Descriptions.Item>
                  <Descriptions.Item label="Sector" span={3}>{reoiData.sector}</Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>


            <Col span={24}>
              <Card bordered={true} style={{ marginTop: '20px' }}>
                <h4>REOI Preview:</h4>
                {reoiData.file.endsWith('.pdf') ? (
                  <iframe title="REOI Document" src={`https://kmsbe.frontieri.com/kmsApi/${reoiData.file}`} style={{ width: "100%", height: "400px" }}></iframe>
                ) : reoiData.file.endsWith('.docx') ? (
                  <div style={{ width: "100%", height: "400px" }}>
                    <DocViewer
                      pluginRenderers={DocViewerRenderers}
                      documents={docs}
                      config={{
                        header: {
                          disableHeader: false,
                          disableFileName: true,
                          retainURLParams: false
                        }
                      }}
                      style={{ height: 400 }}
                    />
                  </div>
                ) : (
                  <p>Unsupported file format</p>
                )}
                <div className="mt-4">
                  <Upload
                    beforeUpload={beforeUpload}
                    fileList={fileList}
                    maxCount={1}
                    accept=".docx,.pdf"
                  >
                    {/* <Button type="dashed" className="ant-full-box" icon={<ToTopOutlined />}>
                      Upload File
                    </Button> */}
                  </Upload>
                  <Button onClick={handleUpload} hidden={!fileList.length}>Submit</Button>
                </div>
              </Card>
            </Col>
          </Row>
          <br />
          <br />

          <Row gutter={[24, 0]} style={{ marginTop: '20px' }}>
            <Col span={24}>
              <Card bordered={false} title={<h6 className="font-semibold m-0">General Objectives</h6>} bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}>
                <p className="text-dark">{reoiData.objectives}</p>
              </Card>
            </Col>
          </Row>

          <Row gutter={[24, 0]} style={{ marginTop: '20px' }}>
            <Col span={24}>
              <Card bordered={false} title={<h6 className="font-semibold m-0">Specific Objectives</h6>} bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}>
                <p className="text-dark">{reoiData.specificObjectives}</p>
              </Card>
            </Col>
          </Row>
        </div>

        {/* Sidebar */}
        <div style={{ flex: 1, padding: '0 20px' }}> {/* Adjusted from 1 to 2 */}
          <Card bordered={false} title={<h6 className="font-semibold m-0">Available Experts</h6>} bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}>
            <CvList reoiId={id} />
          </Card>
          <br />          <br />

          <Card bordered={false} title={<h6 className="font-semibold m-0">Firm Experiences</h6>} bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}>
            <ExperienceList reoiId={id} />
          </Card>
          {/* <Card bordered={false} title={<h6 className="font-semibold m-0">Technical Proposal</h6>} bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}>
            <TpDetail reoiId={id} />
          </Card>
          <Card bordered={false} title={<h6 className="font-semibold m-0">Inception Report</h6>} bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}>
            <REOIIR reoiId={id} />
          </Card>
          <Card bordered={false} title={<h6 className="font-semibold m-0">Final Report</h6>} bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}>
            <REOIFR reoiId={id} />
          </Card> */}
        </div>
      </div>
    </>
  );
}

export default REOIDetail;
