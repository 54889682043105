import React, { useState, useEffect } from 'react';
import { Button, Form, Select, message, Row, Col, Modal, Table, Tooltip } from 'antd';
import useFetchWithToken from '../../services/api';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

const ProjectEngagement = ({ cvId }) => {
  const [projects, setProjects] = useState([]);
  const [engagements, setEngagements] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [form] = Form.useForm();
  const history = useHistory();
  const { data: allProjects, token } = useFetchWithToken('projects');
  const { data: allEngagements } = useFetchWithToken(`hrProjects/${cvId}`);

  useEffect(() => {
    if (allProjects) {
      setProjects(allProjects);
    }
  }, [allProjects]);

  useEffect(() => {
    if (allEngagements) {
      setEngagements(allEngagements);
    }
  }, [allEngagements]);

  const handleAddProject = () => {
    setModalVisible(true);
    setSelectedProject(null);
  };

  const handleCancel = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const onProjectChange = (value) => {
    setSelectedProject(value);
  };

  const onFinish = async (values) => {
    try {
      const data = {
        HRCVId: cvId,
        ProjectId: selectedProject,
        projectEngagement: values.engagement,
        region: values.region,
      };

      await axios.post('https://kmsbe.frontieri.com/kmsApi/hrProjects', data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      message.success('Project engagement saved successfully!');
      setModalVisible(false);
      form.resetFields();

      // Fetch updated engagements after saving
      refreshEngagements();
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        history.push('/login');
        message.error('Session expired. Please login again.');
      } else {
        message.error('Unable to save project engagement!');
      }
    }
  };

  const refreshEngagements = async () => {
    try {
      const { data: updatedEngagements } = await axios.get(`https://kmsbe.frontieri.com/kmsApi/hrProjects/${cvId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEngagements(updatedEngagements);
    } catch (error) {
      console.error('Failed to fetch updated engagements', error);
    }
  };

  const columns = [
    {
      title: 'Project Title',
      dataIndex: ['Project', 'title'],
      key: 'projectTitle',
      render: (text) => <Tooltip title={text}>{text.length > 15 ? `${text.substring(0, 30)}...` : text}</Tooltip>,
    },
    {
      title: 'Engagement Type',
      dataIndex: 'projectEngagement',
      key: 'projectEngagement',
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
    },
  ];

  return (
    <div>
      <Button type="primary" onClick={handleAddProject}>
        Add Project Engagement
      </Button>
      <Table dataSource={engagements} columns={columns} rowKey="id" />

      <Modal
        title="Add Project Engagement"
        visible={modalVisible}
        footer={null}
        onCancel={handleCancel}
        width={800}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Select Project"
                name="project"
                rules={[{ required: true, message: 'Please select a project' }]}
              >
                <Select
                  placeholder="Select a project"
                  onChange={onProjectChange}
                  value={selectedProject}
                >
                  {projects.map((project) => (
                    <Option key={project.id} value={project.id}>
                      {project.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {selectedProject && (
            <>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label="Project Engagement"
                    name="engagement"
                    rules={[{ required: true, message: 'Please select engagement type' }]}
                  >
                    <Select placeholder="Select engagement type">
                      <Option value="Qualitative">Qualitative</Option>
                      <Option value="Quantitative">Quantitative</Option>
                      <Option value="Phone Survey">Phone Survey</Option>
                      <Option value="Supervisor">Supervisor</Option>
                      <Option value="Key Informant Interview">Key Informant Interview</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Region"
                    name="region"
                    rules={[{ required: true, message: 'Please select a region' }]}
                  >
                    <Select placeholder="Select a region">
                      <Option value="AddisAbaba">Addis Ababa</Option>
                      <Option value="Afar">Afar</Option>
                      <Option value="Amhara">Amhara</Option>
                      <Option value="Benishangul Gumuz">Benishangul Gumuz</Option>
                      <Option value="DireDawa">Dire Dawa</Option>
                      <Option value="Gambela">Gambela</Option>
                      <Option value="Harari">Harari</Option>
                      <Option value="Oromia">Oromia</Option>
                      <Option value="Sidama">Sidama</Option>
                      <Option value="Somali">Somali</Option>
                      <Option value="SouthernNations">Southern Nations, Nationalities, and Peoples' Region</Option>
                      <Option value="Tigray">Tigray</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default ProjectEngagement;
