import React, { useState, useEffect } from "react";
import { Table, Popconfirm, Card, Upload, message, Input, Row, Col, Button, Modal, Checkbox, Dropdown, Tabs, Spin, Drawer, notification, Select, Tooltip } from "antd";
import { NavLink, useHistory } from "react-router-dom";
import { ToTopOutlined, SearchOutlined, EditOutlined, ExclamationCircleOutlined, DeleteOutlined, DownOutlined, InfoCircleOutlined } from "@ant-design/icons";
import useFetchWithToken from "../../services/api";
import './style.css'; // Make sure to adjust the path to your custom CSS file

import moment from "moment";
import CreateTp from "./create"; // Import the CreateTp form
import EditTp from "./edit";
import axios from 'axios';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;

function highlightMatchedText(text, query) {
  if (!text || !query || query.trim() === '') return text;
  if (typeof text !== 'string') return text;

  const index = text.toLowerCase().indexOf(query.toLowerCase());
  if (index === -1) return text;

  const maxLength = 20;
  const startIndex = Math.max(0, index - maxLength);
  const endIndex = Math.min(text.length, index + query.length + maxLength);

  const prefix = startIndex > 0 ? '...' : '';
  const suffix = endIndex < text.length ? '...' : '';

  const highlightedText = text.substring(startIndex, endIndex)
    .replace(new RegExp(query, 'gi'), (match) => `<span style="background-color: yellow">${match}</span>`);

  return (
    <span dangerouslySetInnerHTML={{ __html: prefix + highlightedText + suffix }} />
  );
}

const CollapsibleText = ({ text, maxLength = 200 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpansion = () => setIsExpanded(!isExpanded);
  const shouldTruncate = text && text.length > maxLength;

  return (
    <div className={`collapsible-text ${isExpanded ? 'expanded' : ''}`}>
      {isExpanded ? text : (shouldTruncate ? text.substring(0, maxLength) + '...' : text)}
      {shouldTruncate && (
        <span className="toggle" onClick={toggleExpansion}>
          {isExpanded ? 'Show less' : 'Show more'}
        </span>
      )}
    </div>
  );
};

function highlightMatchedText2(text, query) {
  if (!text || !query || query.trim() === '') return text;

  const index = text.toLowerCase().indexOf(query.toLowerCase());
  if (index === -1) return text;

  const maxLength = 2000000;
  const startIndex = Math.max(0, index - maxLength);
  const endIndex = Math.min(text.length, index + query.length + maxLength);

  const prefix = startIndex > 0 ? '...' : '';
  const suffix = endIndex < text.length ? '...' : '';

  const highlightedText = text.substring(startIndex, endIndex)
    .replace(new RegExp(query, 'gi'), (match) => `<span style="background-color: yellow">${match}</span>`);

  return (
    <span dangerouslySetInnerHTML={{ __html: prefix + highlightedText + suffix }} />
  );
}

function TpList() {
  const [uploading, setUploading] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tpsData, setTPData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const { data: fetchedData } = useFetchWithToken("tps");
  const { data: rfps, loading: rfpsLoading, error: rfpsError } = useFetchWithToken('rfps');
  const { postFormData, refetchData, token } = useFetchWithToken("tps");
  const [editMode, setEditMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRowDetails, setSelectedRowDetails] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [titleDetailsVisible, setTitleDetailsVisible] = useState(false); // State for title details drawer
  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState([]);
  const [editableFields, setEditableFields] = useState({});
  const history = useHistory();

  const getUniqueValues = (data, key, nestedKey) => {
    return [...new Set(data.map(item => nestedKey ? item[key]?.[nestedKey] : item[key]))].map(value => ({ text: value, value }));
  };

  const fetchDetails = async (id) => {
    const token = await localStorage.getItem('token');
    try {
      setLoading(true);
      const response = await axios.get(`https://kmsbe.frontieri.com/kmsApi/tps/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setSelectedRowDetails(response.data);
      setDocs([{ uri: `https://kmsbe.frontieri.com/kmsApi/${response.data.file}` }]);
      setLoading(false);
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        history.push('/login');
        message.error("Session expired. Please login again.");
      } else {
        message.error("Unable to load data!");
      }
    }
  };

  const filterAndHighlightData = (data, query) => {
    return data.map(item => {
      const highlightedItem = { ...item };
      if (query.trim() !== '') {
        highlightedItem.title = highlightMatchedText(item.title, query);
        highlightedItem.client = highlightMatchedText(item.client, query);
        highlightedItem.objectives = highlightMatchedText(item.objectives, query);
        highlightedItem.specificObjectives = highlightMatchedText(item.specificObjectives, query);
        highlightedItem.content = highlightMatchedText(item.content, query);
      }
      return highlightedItem;
    });
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value.trim() === '') {
      setFilteredData(tpsData);
    } else {
      const filtered = filterAndHighlightData(tpsData, e.target.value);
      setFilteredData(filtered);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setFilteredData(tpsData);
    defaultColumns[1].filters = getUniqueValues(tpsData, 'sector');
    defaultColumns[2].filters = getUniqueValues(tpsData, 'client');
    defaultColumns[3].filters = getUniqueValues(tpsData, 'year');
    defaultColumns[4].filters = getUniqueValues(tpsData, 'RFP', 'projectType');
  }, [tpsData]);

  const handleUpload = async ({ file }) => {
    try {
      setUploading(true);
      const formData = new FormData();
      formData.append("file", file);
      await postFormData(formData, "uploadTPFull");
      message.success(`${file.name} uploaded successfully`);
      refetchData();
    } catch (error) {
      if (error == 'Error: Request failed with status code 409'){
        message.error(`File Already Exists ${file.name}`);
      } else {
        message.error(`Failed to upload ${file.name}`);
      }
    } finally {
      setUploading(false);
    }
  };

  const handleRowClick = (record) => {
    setSelectedRow(record);
    fetchDetails(record.id);
    setDetailsVisible(true);
  };

  const handleTitleClick = (record) => {
    setSelectedRow(record);
    fetchDetails(record.id);
    setTitleDetailsVisible(true);
  };

  const handleCloseDetails = () => {
    setSelectedRow(null);
    setDetailsVisible(false);
  };

  const handleCloseTitleDetails = () => {
    setSelectedRow(null);
    setTitleDetailsVisible(false);
  };

  const ColumnSelector = ({ columns, selectedColumns, onChange }) => {
    const handleChange = (checkedValues) => {
      onChange(checkedValues);
    };

    return (
      <Checkbox.Group options={columns} defaultValue={selectedColumns} onChange={handleChange} />
    );
  };

  const DynamicTable = ({ columns: initialColumns, data, onRow }) => {
    const defaultDisplayedColumns = initialColumns.map(column => column.key).slice(0, 7);
    const [displayedColumns, setDisplayedColumns] = useState(defaultDisplayedColumns);
    const handleColumnChange = (selectedColumns) => {
      setDisplayedColumns(selectedColumns);
    };
    const filteredColumns = initialColumns.filter(column => displayedColumns.includes(column.key));
    const [pageSize, setPageSize] = useState(10);
    const [current, setCurrent] = useState(1);

    return (
      <>
        <Dropdown
          overlay={
            <ColumnSelector
              columns={initialColumns.map((column) => ({
                label: column.title,
                value: column.key,
              }))}
              selectedColumns={defaultDisplayedColumns}
              onChange={handleColumnChange}
            />
          }
          trigger={["click"]}
        >
          <Button>
            Select Columns <DownOutlined />
          </Button>
        </Dropdown>
        <Table
          columns={filteredColumns}
          dataSource={data}
          pagination={{
            current: current,
            pageSize: pageSize,
            onChange: (page, pageSize) => {
              setCurrent(page);
              setPageSize(pageSize);
            },
            showSizeChanger: true,
            pageSizeOptions: ['5', '10', '20', '50'],
          }}
          className="custom-table"
          onRow={onRow}
        />
      </>
    );
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          placeholder={`Search ${dataIndex}`}
          allowClear
          size="small"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onSearch={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button onClick={() => confirm()} size="small" style={{ width: 90 }}>Search</Button>
        <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>Reset</Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
  });

  const fetchData = async () => {
    const token = await localStorage.getItem('token');
    try {
      setLoading(true);
      const response = await axios.get("https://kmsbe.frontieri.com/kmsApi/tps", {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      setTPData(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        history.push('/login');
        message.error("Session expired. Please login again.");
      } else {
        message.error("Unable to load data!");
      }
    }
  };

  const handleSearch = async () => {
    const token = await localStorage.getItem('token');
    try {
      const response = await axios.post(`https://kmsbe.frontieri.com/kmsApi/tps/search`, {
        query: searchQuery,
      },{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const highlightedData = filterAndHighlightData(response.data, searchQuery);
      setTPData(response.data);
      setFilteredData(highlightedData);
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        history.push('/login');
        message.error("Session expired. Please login again.");
      } else {
        message.error("Unable to load data!");
      }
    }
  };

  const handleEdit = (record) => {
    setEditData(record);
    setEditMode(true);
    setShowCreateModal(true);
  };

  const handleDelete = async (record) => {
    try {
      await axios.delete(`https://kmsbe.frontieri.com/kmsApi/tps/${record}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      message.success('TP deleted successfully.');
      window.location.reload();
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        history.push('/login');
        message.error("Session expired. Please login again.");
      } else {
        message.error("Unable to load data!");
      }
    }
  };

  const handleDeleteButtonClick = (e, record) => {
    e.stopPropagation();
    showDeleteConfirm(record);
  };

  const showDeleteConfirm = (record) => (
    <Popconfirm
      title="Are you sure you want to delete this record?"
      icon={<ExclamationCircleOutlined />}
      okText="Yes"
      okType="danger"
      cancelText="No"
      onConfirm={(e) => {
        e.stopPropagation();
        handleDelete(record);
      }}
      onCancel={(e) => {
        e.stopPropagation();
        console.log('Cancel');
      }}
    >
    </Popconfirm>
  );

  const defaultColumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      ...getColumnSearchProps("title"),
      width: '50%',
      render: (text, record) => (
        <div className="wrap-text" onClick={(e) => { e.stopPropagation(); handleTitleClick(record); }}>
          <CollapsibleText text={text} maxLength={200} />
        </div>
      ),
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      sorter: (a, b) => a.year.localeCompare(b.year),
      filters: getUniqueValues(tpsData, 'year'),
      onFilter: (value, record) => record.year.toString().includes(value),
      width: '5%',
      render: (text) => (
        <div className="wrap-text">
          {text}
        </div>
      ),
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      sorter: (a, b) => a.client.localeCompare(b.client),
      filters: getUniqueValues(tpsData, 'client'),
      onFilter: (value, record) => record.client.includes(value),
      width: '20%',
      render: (text) => (
        <div className="wrap-text">
          {text}
        </div>
      ),
    },
    {
      title: "Sector",
      dataIndex: "sector",
      key: "sector",
      sorter: (a, b) => a.sector.localeCompare(b.sector),
      filters: getUniqueValues(tpsData, 'sector'),
      onFilter: (value, record) => record.sector.includes(value),
      width: '10%',
      render: (text) => (
        <div className="wrap-text">
          <CollapsibleText text={text} maxLength={200} />
        </div>
      ),
    },
    {
      title: "Project Type",
      dataIndex: ["RFP", "projectType"],
      key: "projectType",
      width: '5%',
      sorter: (a, b) => (a.RFP?.projectType || '').localeCompare(b.RFP?.projectType || ''),
      filters: getUniqueValues(tpsData, 'RFP', 'projectType'),
      onFilter: (value, record) => (record.RFP?.projectType || '').includes(value),
      render: (text, record) => record.RFP?.projectType,
    },
    ...(searchQuery ? [
      {
        title: 'Content',
        dataIndex: 'content',
        key: 'content',
        render: (text) => (
          <Tooltip title={text} placement="topLeft" ellipsis={{ tooltip: true }}>
            {highlightMatchedText(text, searchQuery)}
          </Tooltip>
        ),
      },
    ] : []),
  ];

  const handleFieldChange = (field, value) => {
    setEditableFields({ ...editableFields, [field]: value });
  };

  const saveChanges = async () => {
    try {
      const token = await localStorage.getItem('token');
      const updatedData = { ...selectedRow, ...editableFields };
      await axios.put(`https://kmsbe.frontieri.com/kmsApi/tps/${selectedRow.id}`, updatedData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      notification.success({
        message: 'Success',
        description: 'Data updated successfully.',
      });
      setEditableFields({}); // Clear the editable fields
      setSelectedRowDetails({ ...selectedRowDetails, ...editableFields }); // Update the selectedRow with new data
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to update data.',
      });
    }
  };

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col span={12}>
          {/* <Button type="primary" onClick={() => setShowCreateModal(true)}>Add New TP</Button> */}
        </Col>
        <Col span={12}>
          <Search
            placeholder="Search"
            width={10}
            allowClear
            enterButton={<SearchOutlined style={{ fontSize: '25px' }} />}
            size="large"
            onSearch={handleSearch}
            onChange={handleSearchInputChange}
          />
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={selectedRow ? 12 : 24}>
          <Card>
            {loading ? (
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Spin size="large" />
              </div>
            ) : (
              <div style={{ overflowX: 'auto' }}>
                <DynamicTable
                  columns={defaultColumns}
                  onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                  })}
                  data={filteredData}
                  pagination={{ pageSize: 10 }}
                  scroll={{ x: 100, y: 400 }}
                />
              </div>
            )}
          </Card>
          <Card bordered={false}>
            <div className="uploadfile pb-15 shadow-none">
              <Upload
                name="file"
                customRequest={handleUpload}
                beforeUpload={(file) => true}
              >
                <Button
                  type="dashed"
                  className="ant-full-box"
                  icon={<ToTopOutlined />}
                  loading={uploading}
                >
                  Click to Upload
                </Button>
              </Upload>
            </div>
            <Modal
              title={editMode ? "Edit TP" : "Create New TP"}
              visible={showCreateModal}
              width={800}
              onCancel={() => {
                setShowCreateModal(false);
                setEditData(null);
              }}
              footer={null}
            >
              {editMode ? (
                <EditTp
                  formData={editData}
                  setFormData={editData}
                  closeModal={() => setShowCreateModal(false)}
                  refetchData={refetchData}
                  width={800}
                />
              ) : (
                <CreateTp
                  formData={editData}
                  setFormData={setEditData}
                  closeModal={() => setShowCreateModal(false)}
                  refetchData={refetchData}
                />
              )}
            </Modal>
          </Card>
        </Col>
        <Col xs={24} xl={12}>
          <Drawer
            title={selectedRowDetails ? `Details of ${selectedRowDetails.title}` : 'Details'}
            width={640}
            placement="right"
            onClose={handleCloseDetails}
            visible={detailsVisible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            {selectedRowDetails && (
              <div>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Details" key="1">
                    <Card
                      style={{ height: "800px", overflow: "auto" }}
                      headStyle={{ position: 'sticky', top: '0', zIndex: '1', background: '#fff' }}
                    >
                      <p>RFP No: {selectedRowDetails.rfpNo}</p>
                      <div>
                        {selectedRowDetails.content && selectedRowDetails.content.split("\n\n").map((paragraph, index) => (
                          <p
                            key={index}
                            ref={(el) => {
                              if (el && el.innerHTML.includes('<span style="background-color: yellow">')) {
                                el.scrollIntoView({ behavior: 'smooth', block: 'center' });
                              }
                            }}
                          >
                            {highlightMatchedText2(paragraph, searchQuery)}
                          </p>
                        ))}
                      </div>
                    </Card>
                  </TabPane>
                  <TabPane tab="File Preview" key="2">
                    <Card bordered={false} className="header-solid h-full">
                      <h4>TP Preview:</h4>
                      <div style={{ width: "100%", height: "800px" }}>
                        <DocViewer
                          pluginRenderers={DocViewerRenderers}
                          documents={docs}
                          config={{
                            header: {
                              disableHeader: false,
                              disableFileName: true,
                              retainURLParams: false
                            }
                          }}
                          style={{ height: 800 }}
                        />
                      </div>
                    </Card>
                  </TabPane>
                </Tabs>
              </div>
            )}
          </Drawer>
          <Drawer
            title={selectedRowDetails ? `Title Details of ${selectedRowDetails.title}` : 'Title Details'}
            width={640}
            placement="right"
            onClose={handleCloseTitleDetails}
            visible={titleDetailsVisible}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Popconfirm
                    title="Are you sure you want to delete this record?"
                    icon={<ExclamationCircleOutlined />}
                    okText="Yes"
                    okType="danger"
                    cancelText="No"
                    onConfirm={() => handleDelete(selectedRowDetails.id)}
                  >
                    <Button type="primary" danger icon={<DeleteOutlined />}>
                      Delete
                    </Button>
                  </Popconfirm>
                </div>
                <div>
                  <Button onClick={saveChanges} type="primary">Save</Button>
                </div>
              </div>
            }
          >
            {selectedRowDetails && (
              <div>
                <p>
                  Title:
                  <span
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={(e) => handleFieldChange('title', e.target.innerText)}
                    onDoubleClick={(e) => e.target.focus()}
                  >
                    {editableFields.title || selectedRowDetails.title}
                  </span>
                </p>
                <p>
                  RFP No:
                  <span>{console.log("sele",selectedRowDetails)}
                    {selectedRowDetails.RFP ? selectedRowDetails.RFP.rfpNo : 'N/A'}
                  </span>
                </p>
                <p>
                  RFP Title:
                  <NavLink to={`/rfpDetails/${selectedRowDetails.RFP?.id}`}>
                    {selectedRowDetails.RFP?.title}
                  </NavLink>
                  <Select
                    defaultValue={selectedRowDetails.RFP?.id}
                    style={{ width: '100%' }}
                    loading={rfpsLoading}
                    disabled={rfpsError}
                    onChange={(value) => handleFieldChange('rfpId', value)}
                  >
                    {rfps && rfps.map(rfp => (
                      <Option key={rfp.id} value={rfp.id}>
                        {rfp.title}
                      </Option>
                    ))}
                  </Select>
                </p>
                <p>
                  Client:
                  <span
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={(e) => handleFieldChange('client', e.target.innerText)}
                    onDoubleClick={(e) => e.target.focus()}
                  >
                    {editableFields.client || selectedRowDetails.client}
                  </span>
                </p>
                <p>
                  Sector:
                  <span
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={(e) => handleFieldChange('sector', e.target.innerText)}
                    onDoubleClick={(e) => e.target.focus()}
                  >
                    {editableFields.sector || selectedRowDetails.sector}
                  </span>
                </p>
                <p>
                  Project Type:
                  <span
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={(e) => handleFieldChange('projectType', e.target.innerText)}
                    onDoubleClick={(e) => e.target.focus()}
                  >
                    {editableFields.projectType || selectedRowDetails.projectType}
                  </span>
                </p>
                <p>
                  Year:
                  <span
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={(e) => handleFieldChange('year', e.target.innerText)}
                    onDoubleClick={(e) => e.target.focus()}
                  >
                    {editableFields.year || selectedRowDetails.year}
                  </span>
                </p>
                <p>
                  Objective:
                  <span
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={(e) => handleFieldChange('objectives', e.target.innerText)}
                    onDoubleClick={(e) => e.target.focus()}
                  >
                    {editableFields.objectives || selectedRowDetails.objectives}
                  </span>
                </p>
                <p>
                  Specific Objective:
                  <span
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={(e) => handleFieldChange('specificObjectives', e.target.innerText)}
                    onDoubleClick={(e) => e.target.focus()}
                  >
                    {editableFields.specificObjectives || selectedRowDetails.specificObjectives}
                  </span>
                </p>
              </div>
            )}
          </Drawer>
        </Col>
      </Row>
    </div>
  );
}

export default TpList;
