import React, { useState, useEffect } from "react";
import { Table, Popconfirm, Card, Upload, message, Input, Row, notification, Col, Dropdown, Modal, Button, Checkbox, Tag, Tabs, Spin, Drawer, Select } from "antd";
import { NavLink } from "react-router-dom";
import { ToTopOutlined, ExclamationCircleOutlined, DeleteOutlined, SearchOutlined, DownOutlined, EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import useFetchWithToken from "../../services/api";
import RFPForm from "./create";
import EditRFPForm from "./edit";
import moment from 'moment';
import axios from 'axios';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useHistory } from 'react-router-dom';
import CvList from "./cvs";
import ExperienceList from "./experience";
import ExperienceWithClient from "./experienceWithClient";

const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;

function highlightMatchedText(text, query) {
  if (typeof text !== 'string' || !query || query.trim() === '') return text;

  const index = text.toLowerCase().indexOf(query.toLowerCase());
  if (index === -1) return text;

  const maxLength = 20; // Adjust the number of characters to display before and after the highlighted text
  const startIndex = Math.max(0, index - maxLength);
  const endIndex = Math.min(text.length, index + query.length + maxLength);

  const prefix = startIndex > 0 ? '...' : '';
  const suffix = endIndex < text.length ? '...' : '';

  const highlightedText = text.substring(startIndex, endIndex)
    .replace(new RegExp(query, 'gi'), (match) => `<span style="background-color: yellow">${match}</span>`);

  return (
    <span dangerouslySetInnerHTML={{ __html: prefix + highlightedText + suffix }} />
  );
}

const CollapsibleText = ({ text, maxLength = 200 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => setIsExpanded(!isExpanded);

  const shouldTruncate = text && text.length > maxLength;

  return (
    <div className={`collapsible-text ${isExpanded ? 'expanded' : ''}`}>
      {isExpanded ? text : (shouldTruncate ? text.substring(0, maxLength) + '...' : text)}
    </div>
  );
};

function highlightMatchedText2(text, query) {
  if (!text || !query || query.trim() === '') return text;

  const index = text.toLowerCase().indexOf(query.toLowerCase());
  if (index === -1) return text;

  const maxLength = 2000000; // Adjust the number of characters to display before and after the highlighted text
  const startIndex = Math.max(0, index - maxLength);
  const endIndex = Math.min(text.length, index + query.length + maxLength);

  const prefix = startIndex > 0 ? '...' : '';
  const suffix = endIndex < text.length ? '...' : '';

  const highlightedText = text.substring(startIndex, endIndex)
    .replace(new RegExp(query, 'gi'), (match) => `<span style="background-color: yellow">${match}</span>`);

  return (
    <span dangerouslySetInnerHTML={{ __html: prefix + highlightedText + suffix }} />
  );
}

function RFPList() {
  const [RFPModalVisible, setRFPModalVisible] = useState(false);
  const [RFPEModalVisible, setRFPEModalVisible] = useState(false);
  const [selectedRowDetails, setSelectedRowDetails] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const history = useHistory();
  const [includePlaceholder, setIncludePlaceholder] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const { postFormData, refetchData, error, token } = useFetchWithToken("rfps");
  const [rfpData, setRFPData] = useState([]);
  const { data: fetchedData, loading } = useFetchWithToken("rfps");
  const [uploading, setUploading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [current, setCurrent] = useState(1);
  const [docs, setDocs] = useState([]);
  const [editableFields, setEditableFields] = useState({});

  const getUniqueValues = (data, key) => {
    return [...new Set(data.map(item => item[key]))].map(value => ({ text: value, value }));
  };

  const docs2 = [
    { uri: "https://docs.google.com/document/d/1tl99rMUJauJCwGzkQvjIiKnLDgp7XSUl/export?format=docx", fileType: "docx", fileName: "demo.docx" },
  ];

  const ColumnSelector = ({ columns, selectedColumns, onChange }) => {
    const handleChange = (checkedValues) => {
      onChange(checkedValues);
    };

    return (
      <Checkbox.Group options={columns} defaultValue={selectedColumns} onChange={handleChange} />
    );
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          placeholder={`Search ${dataIndex}`}
          allowClear
          size="small"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onSearch={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button onClick={() => confirm()} size="small" style={{ width: 90 }}>Search</Button>
        <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>Reset</Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
  });

  const fetchDetails = async (id) => {
    const token = await localStorage.getItem('token');
    try {
      setLoading2(true);
      const response = await axios.get(`https://kmsbe.frontieri.com/kmsApi/rfps/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setSelectedRowDetails(response.data);
      setDocs([{ uri: `https://kmsbe.frontieri.com/kmsApi/${response.data.file}` }]);
      setLoading2(false);
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        history.push('/login');
        message.error("Session expired. Please login again.");
      } else {
        console.log(error)
        message.error("Unable to load data!");
      }
    }
  };

  const handleRowClick = (record) => {
    setSelectedRow(record);
    fetchDetails(record.id);
    setDetailsVisible(true);
  };

  const handleFieldChange = (field, value) => {
    setEditableFields(prev => ({ ...prev, [field]: value }));
  };

  const handleDelete = async (record) => {
    try {
      await axios.delete(`https://kmsbe.frontieri.com/kmsApi/rfps/${record}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      message.success('RFP deleted successfully.');
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        history.push('/login');
        message.error("Session expired. Please login again.");
      } else {
        message.error("Unable to load data!");
      }
    }
  };

  const handleDeleteButtonClick = (e, record) => {
    e.stopPropagation(); // Stop event propagation
    showDeleteConfirm(record);
  };

  const showDeleteConfirm = (record) => {
    <Popconfirm
      title="Are you sure you want to delete this record?"
      icon={<ExclamationCircleOutlined />}
      okText="Yes"
      okType="danger"
      cancelText="No"
      onConfirm={(e) => {
        e.stopPropagation(); // Prevent default behavior
        handleDelete(record);
      }}
      onCancel={(e) => {
        e.stopPropagation(); // Prevent default behavior
        console.log('Cancel');
      }}
    />;
  };

  const handleCloseDetails = () => {
    window.location.reload();

  };

  const DynamicTable = ({ columns: initialColumns, data }) => {
    const defaultDisplayedColumns = initialColumns.map(column => column.key).slice(0, 7); // Select first two columns by default
    const [displayedColumns, setDisplayedColumns] = useState(defaultDisplayedColumns);

    const handleColumnChange = (selectedColumns) => {
      setDisplayedColumns(selectedColumns);
    };

    const filteredColumns = initialColumns.filter(column => displayedColumns.includes(column.key));

    return (
      <>
        <Dropdown
          overlay={
            <ColumnSelector
              columns={initialColumns.map((column) => ({
                label: column.title,
                value: column.key,
              }))}
              selectedColumns={defaultDisplayedColumns}
              onChange={handleColumnChange}
            />
          }
          trigger={["click"]}
        >
          <Button>
            Select Columns <DownOutlined />
          </Button>
        </Dropdown>
        <Table columns={filteredColumns} dataSource={data} />
      </>
    );
  };

  const fetchData = async () => {
    try {
      const response = await axios.get("https://kmsbe.frontieri.com/kmsApi/rfps", {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setRFPData(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        history.push('/login');
        message.error("Session expired. Please login again.");
      } else {
        message.error("Unable to load data!");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    applyFilters();
    columns[1].filters = getUniqueValues(rfpData, 'client');
    columns[2].filters = getUniqueValues(rfpData, 'country');
    columns[3].filters = getUniqueValues(rfpData, 'sector');
    detailColumns[1].filters = getUniqueValues(rfpData, 'client');
    detailColumns[2].filters = getUniqueValues(rfpData, 'country');
    detailColumns[3].filters = getUniqueValues(rfpData, 'sector');
  }, [rfpData]);

  const applyFilters = () => {
    let data = includePlaceholder ? rfpData : rfpData.filter(item => !item.title.startsWith("Placeholder"));
    if (searchQuery.trim() !== '') {
      data = filterAndHighlightData(data, searchQuery);
    }
    setFilteredData(data);
  };

  const [filteredData, setFilteredData] = useState([]);

  const filterAndHighlightData = (data, query) => {
    return data.map(item => {
      const highlightedItem = { ...item };
      if (query.trim() !== '') {
        highlightedItem.title = highlightMatchedText(item.title, query);
        highlightedItem.client = highlightMatchedText(item.client, query);
        highlightedItem.objectives = highlightMatchedText(item.objectives, query);
        highlightedItem.specificObjectives = highlightMatchedText(item.specificObjectives, query);
        highlightedItem.content = highlightMatchedText(item.content, query);
      }
      return highlightedItem;
    });
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value.trim() === '') {
      setFilteredData(rfpData);
    } else {
      const filtered = filterAndHighlightData(rfpData, e.target.value);
      setFilteredData(filtered);
    }
  };

  const handleSearch = async () => {
    const token = await localStorage.getItem('token');
    try {
      const response = await axios.post(`https://kmsbe.frontieri.com/kmsApi/rfps/search`, {
        query: searchQuery,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const highlightedData = filterAndHighlightData(response.data, searchQuery);
      setRFPData(response.data); // Update the main data
      setFilteredData(highlightedData); // Update the filtered data with highlights
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        history.push('/login');
        message.error("Session expired. Please login again.");
      } else {
        message.error("Unable to load data!");
      }
    }
  };

  const handleAddRFP = () => {
    setFormData({});
    setRFPModalVisible(true);
  };

  const handleEditRFP = (record) => {
    setFormData(record);
    setRFPModalVisible(true);
  };

  const handleEdit = (record) => {
    setEditMode(true);
    const formattedIssuedOn = moment(record.issuedOn).format('YYYY-MM-DD');
    setFormData({ ...record, issuedOn: formattedIssuedOn });
    setRFPEModalVisible(true);
  };

  const closeModal = () => {
    setEditMode(false);
    setFormData(null);
    setRFPModalVisible(false);
    setRFPEModalVisible(false);
    setSelectedRow(null);
  };

  const handleUpload = async ({ file }) => {
    try {
      setUploading(true);
      const formData = new FormData();
      formData.append("file", file);
      await postFormData(formData, 'uploadRFP');
      message.success(`${file.name} uploaded successfully`);
    } catch (error) {
      if (error === 'Error: Request failed with status code 409') {
        message.error(`File Already Exists ${file.name}`);
      } else {
        message.error(`Failed to upload ${file.name}`);
      }
    } finally {
      setUploading(false);
    }
  };

  const handleCheckboxChange = (e) => {
    setIncludePlaceholder(e.target.checked);
    applyFilters();
  };

  const handleSave = async () => {
    try {
      const response = await axios.put(`https://kmsbe.frontieri.com/kmsApi/rfps/${selectedRowDetails.id}`, {
        ...selectedRowDetails,
        ...editableFields,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      notification.success({
        message: 'Success',
        description: 'Data updated successfully.',
      });     
      // setDetailsVisible(false);
    //  fetchData();
    } catch (error) {
      message.error("Failed to save changes.");
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ...getColumnSearchProps("title"),
      width: '50%',
      render: (text) => (
        <div className="wrap-text">
          <CollapsibleText text={text} maxLength={300} />
        </div>
      ),
    },
    {
      title: "Year",
      dataIndex: "issuedOn",
      key: "issuedOn",
      // sorter: (a, b) => a.issuedOn.localeCompare(b.issuedOn),
      filters: getUniqueValues(rfpData, 'issuedOn'),
      onFilter: (value, record) => record.issuedOn.includes(value),
      width: '5%',
      render: (text) => (
        <div className="wrap-text">
          <CollapsibleText text={text} maxLength={4} />
        </div>
      ),
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      // sorter: (a, b) => a.client.localeCompare(b.client),
      filters: getUniqueValues(rfpData, 'client'),
      onFilter: (value, record) => record.client.includes(value),
      width: '20%',
      render: (text) => (
        <div className="wrap-text">
          <CollapsibleText text={text} maxLength={200} />
        </div>
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      // sorter: (a, b) => a.country.localeCompare(b.country),
      filters: getUniqueValues(rfpData, 'country'),
      onFilter: (value, record) => record.country.includes(value),
      width: '10%',
      render: (text) => (
        <div className="wrap-text">
          <CollapsibleText text={text} maxLength={20} />
        </div>
      ),
    },
    {
      title: "Sector",
      dataIndex: "sector",
      key: "sector",
      // sorter: (a, b) => a.sector.localeCompare(b.sector),
      filters: getUniqueValues(rfpData, 'sector'),
      onFilter: (value, record) => record.sector.includes(value),
      width: '5%',
      render: (text) => (
        <div className="wrap-text">
          <CollapsibleText text={text} maxLength={200} />
        </div>
      ),
    },
    {
      title: "Project Type",
      dataIndex: "projectType",
      key: "projectType",
      // sorter: (a, b) => a.projectType.localeCompare(b.projectType),
      filters: getUniqueValues(rfpData, 'projectType'),
      onFilter: (value, record) => record.sector.includes(value),
      width: '5%',
      render: (text) => (
        <div className="wrap-text">
          <CollapsibleText text={text} maxLength={200} />
        </div>
      ),
    },
 
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: '5%',
      render: (status) => {
        let color = '';
        switch (status) {
          case 'Bid':
            color = 'green';
            break;
          case 'No Bid':
            color = 'red';
            break;
          case 'Pending':
            color = 'orange';
            break;
          default:
            color = '';
        }
        return (
          <Tag color={color}>{status}</Tag>
        );
      }
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   width: '5%',
    //   render: (text, record) => (
    //     <>
    //       <Button type="link" onClick={() => handleRowClick(record)}><InfoCircleOutlined /> &nbsp;Details</Button>
    //       <Button type="link" icon={<EditOutlined />} onClick={() => handleEdit(record)}>Edit</Button>
    //     </>
    //   )
    // }
  ];

  const detailColumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ...getColumnSearchProps("title"),
      width: '40%',
      render: (text) => (
        <div className="wrap-text">
          <CollapsibleText text={text} maxLength={300} />
        </div>
      ),
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      sorter: (a, b) => a.client.localeCompare(b.client),
      filters: getUniqueValues(rfpData, 'client'),
      onFilter: (value, record) => record.client.includes(value),
      width: '10%',
      render: (text) => (
        <div className="wrap-text">
          <CollapsibleText text={text} maxLength={200} />
        </div>
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      sorter: (a, b) => a.country.localeCompare(b.country),
      filters: getUniqueValues(rfpData, 'country'),
      onFilter: (value, record) => record.country.includes(value),
      width: '10%',
      render: (text) => (
        <div className="wrap-text">
          <CollapsibleText text={text} maxLength={20} />
        </div>
      ),
    },
    {
      title: "Sector",
      dataIndex: "sector",
      key: "sector",
      sorter: (a, b) => a.sector.localeCompare(b.sector),
      filters: getUniqueValues(rfpData, 'sector'),
      onFilter: (value, record) => record.sector.includes(value),
      width: '5%',
      render: (text) => (
        <div className="wrap-text">
          <CollapsibleText text={text} maxLength={200} />
        </div>
      ),
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (text, record) => (
    //     <>
    //       <NavLink to={`/rfpDetails/${record.id}`} style={{ color: 'green' }}>
    //         <InfoCircleOutlined /> &nbsp;Details
    //       </NavLink>
    //       <Button type="link" icon={<EditOutlined />} onClick={() => handleEdit(record)}>Edit</Button>
    //     </>
    //   )
    // }
  ];

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col span={12}>
          {/* <NavLink to="#" onClick={handleAddRFP} className="ant-btn ant-btn-primary" role="button">
            Add RFP
          </NavLink> */}
        </Col>
        <Col span={12}>
          <Search
            placeholder="Search"
            allowClear
            enterButton={<SearchOutlined style={{ fontSize: '25px' }} />}
            size="large"
            onSearch={handleSearch}
            onChange={handleSearchInputChange}
          />
        </Col>
        <Checkbox checked={includePlaceholder} style={{ marginLeft: 10, color: '#1890ff' }} onChange={handleCheckboxChange}>
          Show Placeholder RFP's
        </Checkbox>
      </Row>
      <br />

      {loading || loading2 ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spin size="large" />
        </div>
      ) : (
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={selectedRowDetails ? 12 : 24}>
            <Card>
              <div style={{ overflowX: 'auto' }}>
                <Table
                  columns={selectedRowDetails ? detailColumns : columns}
                  dataSource={filteredData}
                  pagination={{
                    current: current,
                    pageSize: pageSize,
                    onChange: (page, pageSize) => {
                      setCurrent(page);
                      setPageSize(pageSize);
                    },
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '50'],
                  }}
                  className="ant-border-space"
                  onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                  })}
                />
              </div>
            </Card>
          </Col>
          <Col xs={24} xl={12}>
            <Drawer
              title={`Details of ${selectedRowDetails?.title}`}
              width={720}
              onClose={handleCloseDetails}
              visible={detailsVisible}
              bodyStyle={{ paddingBottom: 80 }}
              footer={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <Popconfirm
                      title="Are you sure you want to delete this record?"
                      icon={<ExclamationCircleOutlined />}
                      okText="Yes"
                      okType="danger"
                      cancelText="No"
                      onConfirm={() => handleDelete(selectedRowDetails.id)}
                    >
                      <Button type="primary" danger icon={<DeleteOutlined />}>
                        Delete
                      </Button>
                    </Popconfirm>
                  </div>
                  <div>
                    <Button onClick={handleSave} type="primary">Save</Button>
                  </div>
                </div>
              }
            >
              <Tabs defaultActiveKey="1">
                <TabPane tab="General Information" key="1">
                  {selectedRowDetails && (
                    <div>
                      <p>
                        Title:
                        <span
                          contentEditable
                          suppressContentEditableWarning
                          onBlur={(e) => handleFieldChange('title', e.target.innerText)}
                          onDoubleClick={(e) => e.target.focus()}
                        >
                          {editableFields.title || selectedRowDetails.title}
                        </span>
                      </p>
                      <p>
                        RFP No:
                        <span>{selectedRowDetails.rfpNo || 'N/A'}</span>
                      </p>
                      <p>
                        Client:
                        <span
                          contentEditable
                          suppressContentEditableWarning
                          onBlur={(e) => handleFieldChange('client', e.target.innerText)}
                          onDoubleClick={(e) => e.target.focus()}
                        >
                          {editableFields.client || selectedRowDetails.client}
                        </span>
                      </p>
                      <p>
                        Country:
                        <span
                          contentEditable
                          suppressContentEditableWarning
                          onBlur={(e) => handleFieldChange('country', e.target.innerText)}
                          onDoubleClick={(e) => e.target.focus()}
                        >
                          {editableFields.country || selectedRowDetails.country}
                        </span>
                      </p>
                      <p>
                        Sector:
                        <span
                          contentEditable
                          suppressContentEditableWarning
                          onBlur={(e) => handleFieldChange('sector', e.target.innerText)}
                          onDoubleClick={(e) => e.target.focus()}
                        >
                          {editableFields.sector || selectedRowDetails.sector}
                        </span>
                      </p>
                      <p>
  Project Type:
  <span
    contentEditable
    suppressContentEditableWarning
    onBlur={(e) => handleFieldChange('projectType', e.target.innerText)}
    onDoubleClick={(e) => e.target.focus()}
  >
    {editableFields.projectType || selectedRowDetails.projectType}
  </span>
</p>

                      <p>
                        Objectives:
                        <span
                          contentEditable
                          suppressContentEditableWarning
                          onBlur={(e) => handleFieldChange('objectives', e.target.innerText)}
                          onDoubleClick={(e) => e.target.focus()}
                        >
                          {editableFields.objectives || selectedRowDetails.objectives}
                        </span>
                      </p>
                      <p>
                        Specific Objectives:
                        <span
                          contentEditable
                          suppressContentEditableWarning
                          onBlur={(e) => handleFieldChange('specificObjectives', e.target.innerText)}
                          onDoubleClick={(e) => e.target.focus()}
                        >
                          {editableFields.specificObjectives || selectedRowDetails.specificObjectives}
                        </span>
                      </p>
                      <p>
                        Status:
                        <span
                          contentEditable
                          suppressContentEditableWarning
                          onBlur={(e) => handleFieldChange('status', e.target.innerText)}
                          onDoubleClick={(e) => e.target.focus()}
                        >
                          {editableFields.status || selectedRowDetails.status}
                        </span>
                      </p>
                      {/* <Button type="primary" onClick={handleSave}>Save</Button>
                      <Button type="danger" onClick={handleCloseDetails}>Close</Button> */}
                    </div>
                  )}
                </TabPane>
                <TabPane tab="File Preview" key="2">
                  <Card bordered={false} className="header-solid h-full">
                    <h4>RFP Preview:</h4>
                    <div style={{ width: "100%", height: "800px" }}>
                      <DocViewer
                        pluginRenderers={DocViewerRenderers}
                        documents={docs}
                        config={{
                          header: {
                            disableHeader: false,
                            disableFileName: true,
                            retainURLParams: false
                          }
                        }}
                        style={{ height: 800 }}
                      />
                    </div>
                  </Card>
                </TabPane>
                <TabPane tab="Available Experts" key="3">
                  <CvList rfpId={selectedRowDetails?.id} />
                </TabPane>
                <TabPane tab="Firm Experiences" key="4">
                  <ExperienceList rfpId={selectedRowDetails?.id} />
                </TabPane>

                <TabPane tab="Previous Experiences with the client" key="5">
                  <ExperienceWithClient rfpId={selectedRowDetails?.id} />
                </TabPane>
              </Tabs>
            </Drawer>
          </Col>
        </Row>
      )}
      <Card bordered={false}>
        <div className="uploadfile pb-15 shadow-none">
          <Upload
            name="file"
            customRequest={handleUpload}
            beforeUpload={(file) => {
              const isExcel =
                file.type === "application/docx" ||
                file.type ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              if (1 > 2) {
                message.error("You can only upload Excel files!");
              }
              return true;
            }}
          >
            <Button
              type="dashed"
              className="ant-full-box"
              icon={<ToTopOutlined />}
              loading={uploading}
            >
              Click to Upload
            </Button>
          </Upload>
        </div>
      </Card>
      <Modal
        title={"Add RFP"}
        visible={RFPModalVisible}
        onCancel={closeModal}
        footer={null}
      >
        <RFPForm
          formData={formData}
          setFormData={setFormData}
          closeModal={closeModal}
          refetchData={refetchData}
        />
      </Modal>
      <Modal
        title={"Edit RFP"}
        visible={RFPEModalVisible}
        onCancel={closeModal}
        footer={null}
      >
        {editMode && (
          <EditRFPForm
            formData={formData}
            setFormData={setFormData}
            visible={RFPEModalVisible}
            closeModal={closeModal}
            refetchData={() => {
              console.log("Refetching RFP data...");
            }}
          />
        )}
      </Modal>
    </div>
  );
}

export default RFPList;
