/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import CvList from "./components/cv/list";
import HRCVList from "./components/hrcv/list";
import HRCVDetail from "./components/hrcv/detail";

import CvDetail from "./components/cv/detail";
import RFPList from "./components/rfp/list";
import RFPDetail from "./components/rfp/detail";
import TpList from "./components/tp/list";
import TpDetail from "./components/tp/detail";

// import EOIPList from "./components/eoi/list";
// import EOIPDetail from "./components/eoi/detail";
import FirmExperience from "./components/project/firmExperience";
import IR from "./components/ir/IR";
import FR from "./components/fr/FR";
import EOIList from "./components/eoi/list";
import REOIList from "./components/reoi/list";
import REOIDetail from "./components/reoi/detail";


import EOIDetail from "./components/eoi/detail";

import FirmExperienceDetail from "./components/project/detail";
import IRDetail from "./components/ir/detail";

import FRDetail from "./components/fr/detail";


import User from "./components/userManagement/user";
import Team from "./components/teams/teamList";
import TeamMember from "./components/teamMember/teamMemberList";
import Permission from "./components/userManagement/permission";
import Role from "./components/userManagement/role";
import Login from "./components/userManagement/login";

import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Report from "./components/reports/reportList";
import RFPReportList from "./components/reports/rfpReportList";

import EOIReportList from "./components/reports/eoiReportList";
import REOIReportList from "./components/reports/reoiReportList";
import UpdateProfile from "./components/userManagement/updateProfile";
import Logs from "./components/userManagement/logs";
import HRCVEvaluation from "./components/hrcv/evaluation";
import cvEvaluation from "./components/cv/cvEvaluation";





function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/" exact component={Login} />

        <Route path="/login" exact component={Login} />

        <Main>
          <Route exact path="/dashboard" component={Home} />
          <Route exact path="/tables" component={Tables} />
          <Route exact path="/billing" component={Billing} />
          <Route exact path="/rtl" component={Rtl} />
          <Route exact path="/profile" component={Profile} />

          <Route exact path="/cv" component={CvList} />
          <Route exact path="/hrcv" component={HRCVList} />
          <Route exact path="/dataCollectorEvaluations" component={HRCVEvaluation} />
          <Route exact path="/expertEvaluations" component={cvEvaluation} />



          <Route exact path="/cvDetails/:id" component={CvDetail} />
          <Route exact path="/hrcvDetails/:id" component={HRCVDetail} />

          <Route exact path="/rfp" component={RFPList} />
          <Route exact path="/eoi" component={EOIList} />
          <Route exact path="/reoi" component={REOIList} />


          <Route exact path="/firmExperience" component={FirmExperience} />
          <Route exact path="/ir" component={IR} />

          <Route exact path="/fr" component={FR} />
          <Route exact path="/logs" component={Logs} />


          <Route exact path="/firmExperienceDetails/:id" component={FirmExperienceDetail} />
          <Route exact path="/irDetails/:id" component={IRDetail} />
          <Route exact path="/frDetails/:id" component={FRDetail} />




          <Route exact path="/rfpDetails/:id" component={RFPDetail} />
          <Route exact path="/eoiDetails/:id" component={EOIDetail} />
          <Route exact path="/reoiDetails/:id" component={REOIDetail} />


          <Route exact path="/tp" component={TpList} />
          <Route exact path="/tpDetails/:id" component={TpDetail} />
          <Route exact path="/users" component={User} />

          <Route exact path="/teams" component={Team} />
          <Route exact path="/reports" component={Report} />
          <Route exact path="/rfpreports" component={RFPReportList} />
          <Route exact path="/eoireports" component={EOIReportList} />
          <Route exact path="/reoireports" component={REOIReportList} />





          <Route exact path="/teams/:id" component={Team} />
          <Route exact path="/teamMembers" component={TeamMember} />

          <Route exact path="/roles" component={Role} />
          <Route exact path="/permissions" component={Permission} />

          <Route exact path="/profile-update" component={UpdateProfile} />


          {/* <Redirect from="*" to="/dashboard" /> */}
        </Main>
      </Switch>
    </div>
  );
}

export default App;
