import { Menu, Button, Collapse } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { PieChartOutlined, CheckOutlined, QuestionCircleOutlined, UsergroupAddOutlined, TeamOutlined, ClockCircleOutlined, BarChartOutlined, BookOutlined, PicLeftOutlined, BuildOutlined, FileOutlined, SearchOutlined, EditOutlined, ExclamationCircleOutlined, DeleteOutlined, DownOutlined, InfoCircleOutlined, QuestionCircleFilled, BookFilled, ClockCircleFilled, CheckCircleFilled, FlagFilled, TagFilled, BuildFilled, PieChartFilled, ApiFilled } from "@ant-design/icons";
import { BarChartRounded, Storage } from "@material-ui/icons";

const { Panel } = Collapse;

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  const dashboard = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={color}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={color}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={color}
      ></path>
    </svg>
  );
  const profile = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
        fill={color}
      ></path>
    </svg>,
  ];

  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>KMS</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item className="menu-item-header" key="55">
          Knowledge Mngmt. System
        </Menu.Item>
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span className="icon" style={{ background: page === "dashboard" ? color : "" }}>
              {dashboard}
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Collapse bordered={false} defaultActiveKey={['1']}>
          <Panel header="Documents" key="1">
            <Menu.Item key="11">
              <NavLink to="/cv">
                <span className="icon" style={{ background: page === "tables" ? color : "" }}>
                  <FileOutlined />
                </span>
                <span className="label">Curriculum Vitae</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="11">
              <NavLink to="/hrcv">
                <span className="icon" style={{ background: page === "tables" ? color : "" }}>
                  <Storage />
                </span>
                <span className="label">Data Collectors</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="5073">
              <NavLink to="/rfp">
                <span className="icon" style={{ background: page === "tables" ? color : "" }}>
                  <QuestionCircleFilled />
                </span>
                <span className="label">Req. For Proposal</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="507793333">
              <NavLink to="/reoi">
                <span className="icon" style={{ background: page === "tables" ? color : "" }}>
                  <TagFilled style={{ color: 'blue' }} />
                </span>
                <span className="label">Req. For EOI</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="507793">
              <NavLink to="/eoi">
                <span className="icon" style={{ background: page === "tables" ? color : "" }}>
                  <TagFilled style={{ color: 'blue' }} />
                </span>
                <span className="label">Exp. Of Interest</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="19">
              <NavLink to="/tp">
                <span className="icon" style={{ background: page === "tables" ? color : "" }}>
                  <BookFilled />
                </span>
                <span className="label">Technical Proposal</span>
              </NavLink>
            </Menu.Item>

        <Menu.Item key="5163">
          <NavLink to="/ir">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
<ClockCircleFilled color="blue" />            </span>
            <span className="label">Inception Report</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="5123">
          <NavLink to="/fr">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
<CheckCircleFilled color="blue" />              </span>
            <span className="label">Final Report</span>
          </NavLink>
        </Menu.Item>


    
        <Menu.Item key="5063">
          <NavLink to="/firmExperience">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
<BuildFilled color="blue" />          </span>
            <span className="label">Firm Experience</span>
          </NavLink>
        </Menu.Item>
          </Panel>

          <Panel header="Evaluations" key="2">
            <Menu.Item key="13788768334">
              <NavLink to="/expertEvaluations">
                <span className="icon"><PieChartFilled /></span>
                <span className="label">Researchers</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="1378873314">
              <NavLink to="/dataCollectorEvaluations">
                <span className="icon"><BarChartRounded /></span>
                <span className="label">Data Collectors</span>
              </NavLink>
            </Menu.Item>
         
          </Panel>
          <Panel header="Reports" key="2">
            <Menu.Item key="13788764">
              <NavLink to="/reports">
                <span className="icon"><PieChartFilled /></span>
                <span className="label">TP Reports</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="1378873314">
              <NavLink to="/eoireports">
                <span className="icon"><BarChartRounded /></span>
                <span className="label">EOI Reports</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="137887314">
              <NavLink to="/rfpreports">
                <span className="icon"><BarChartRounded /></span>
                <span className="label">RFP Reports</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="137887314">
              <NavLink to="/reoireports">
                <span className="icon"><BarChartRounded /></span>
                <span className="label">REOI Reports</span>
              </NavLink>
            </Menu.Item>
          </Panel>

          <Panel header="Other Tools" key="89">
          <Menu.Item key="50664894">
        <a href="https://app.asana.com/-/login" target="_blank" rel="noopener noreferrer">
          <span
            className="icon"
            style={{
              background: page === "tables" ? color : "",
            }}
          >
            <ApiFilled style={{ color: 'blue' }} />
          </span>
          <span className="label">Asana</span>
        </a>
      </Menu.Item>
          </Panel>
          <Panel header="User Management" key="3">
            <Menu.Item key="1384944">
              <NavLink to="/logs">
                <span className="icon"><ClockCircleFilled /></span>
                <span className="label">Activity Logs</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="12">
              <NavLink to="/users">
                <span className="icon" style={{ background: page === "profile" ? color : "" }}>
                  {profile}
                </span>
                <span className="label">Users</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="13">
              <NavLink to="/roles">
                <span className="icon"><UsergroupAddOutlined /></span>
                <span className="label">Roles</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="1308764">
              <NavLink to="/teams">
                <span className="icon"><TeamOutlined /></span>
                <span className="label">Teams</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="14">
              <NavLink to="/permissions">
                <span className="icon"><TeamOutlined /></span>
                <span className="label">Permissions</span>
              </NavLink>
            </Menu.Item>
          </Panel>
        </Collapse>
      </Menu>
      <div className="aside-footer">
        <div className="footer-box" style={{ background: color }}>
          <span className="icon" style={{ color }}>
            {dashboard}
          </span>
          <h6>Need Help?</h6>
          <p>Please check our docs</p>
          <Button type="primary" className="ant-btn-sm ant-btn-block">
            DOCUMENTATION
          </Button>
        </div>
      </div>
    </>
  );
}

export default Sidenav;
