import React, { useState } from "react";
import { Row, Col, Card, Upload, message, Descriptions, Button, Tag, Spin } from "antd";
import { useParams } from "react-router-dom";
import { Avatar } from 'antd';
import { UserOutlined, ToTopOutlined } from '@ant-design/icons';
import useFetchWithToken from '../../services/api';
import CvProjects from './cvProjects';
import ProjectEngagement from './engagement'; // Ensure this is imported with the correct name and capitalization
import { Switch } from 'antd';
import axios from "axios";
function HRCVDetail() {
  const [fileList, setFileList] = useState([]);
  const { id } = useParams();
  const { data: cvData, loading, postFormData , token} = useFetchWithToken(`hrcvs/${id}`);

  const beforeUpload = (file) => {
    setFileList([file]);
    return false;
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", fileList[0]);

    try {
      await postFormData(formData, `uploadHRCV/${id}`);
      message.success("File uploaded successfully!");
      setFileList([]);
    } catch (error) {
      message.error("File upload failed. Please try again.");
      setFileList([]);
    }
  };

  const [status, setStatus] = useState(cvData ? cvData.status : null);
  const [licence, setLicence] = useState(cvData ? cvData.licence : null);

  
  const handleToggle = async (checked) => {
    const newStatus = checked ? 'Available' : 'Not Available';

    try {
      await axios.put(`https://kmsbe.frontieri.com/kmsApi/hrcvs/status/${id}`, { status: newStatus }, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      setStatus(newStatus);
      message.success(`Status updated to ${newStatus}`);
    } catch (error) {
      message.error('Failed to update status');
    }
  };

  return (
    <>
      {loading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          {/* Profile Information */}
          <Row gutter={[24, 0]}>
            <Col span={24} md={12} className="mb-24">
              <div style={{ height: "100%" }}>
                <Card
                  bordered={false}
                  title={<h6 className="font-semibold m-0">Profile Information</h6>}
                  className="header-solid h-full card-profile-information"
                  bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
                >
                  {/* User Icon */}
                  <div style={{ textAlign: 'center', marginBottom: 16 }}>
                    <Avatar size={250} icon={<UserOutlined />} style={{ backgroundColor: '#1890ff' }}/>
                  </div>
                  {/* Profile Information */}
                  <Descriptions style={{ marginTop: '60px' }} column={2}>
                    <Descriptions.Item label="Full Name" span={1}>
                      {cvData && cvData.expertName}
                    </Descriptions.Item>
                    <Descriptions.Item label="Gender" span={1}>
                      {cvData && cvData.gender}
                    </Descriptions.Item>
                    <Descriptions.Item label="Phone Number" span={1}>
                      {cvData && cvData.phoneNumber}
                    </Descriptions.Item>
                    <Descriptions.Item label="Region" span={1}>
                      {cvData && cvData.region}
                    </Descriptions.Item>
                    <Descriptions.Item label="Language" span={1}>
                      {cvData && cvData.language}
                    </Descriptions.Item>
                    <Descriptions.Item label="Educational Background" span={1}>
                      {cvData && cvData.educationalBackground}
                    </Descriptions.Item>
                    <Descriptions.Item label="Years of Experience" span={1}>
                      {cvData && cvData.yearsOfExperience}
                    </Descriptions.Item>
                    <Descriptions.Item label="Average Score" span={3}>
                      {cvData && (
                        <>
                          <span
                            style={{
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              display: 'inline-block',
                              marginRight: '8px',
                              backgroundColor:
                                cvData.averagePoints >= 3.5 ? 'green' :
                                  cvData.averagePoints > 2.5 ? 'darkorange' :
                                    'darkred',
                            }}
                          ></span>
                          {cvData.averagePoints}
                        </>
                      )}
                    </Descriptions.Item>

                    <Descriptions.Item label="Availablity" span={1}>
                    <Switch
                    label= 'Availablity'
        checked={status === 'Available' || status === null}
        onChange={handleToggle}
      />                     </Descriptions.Item>
                  </Descriptions>
                  {/* Tags */}
                  <div>
                    {/* <Tag color="darkgreen">{cvData && cvData.availablity}</Tag> */}
                    {/* <Tag color="#faad14">{cvData && cvData.averagePoints}</Tag> */}
                    {/* <Switch
                    label= 'Availablity'
        checked={status === 'Available' || status === null}
        onChange={handleToggle}
      />  */}
                  </div>
                </Card>
              </div>
            </Col>
            {/* Upload CV */}
            <Col span={24} md={12} className="mb-24">
              <Card bordered={false} className="header-solid h-full">
              {cvData && cvData.licence && (
  <>
    <h4>ID/Licence Preview:</h4>
    {cvData.licence.endsWith('.pdf') ? (
      <iframe 
        title="Licence Preview" 
        src={`https://kmsbe.frontieri.com/kmsApi/${cvData.licence}`} 
        style={{ width: "100%", height: "400px" }} 
      ></iframe>
    ) : ['.docx'].some(ext => cvData.licence.endsWith(ext)) ? (
      <iframe 
        title="Licence Preview" 
        src={`https://docs.google.com/viewer?url=https://kmsbe.frontieri.com/kmsApi/${cvData.licence}&embedded=true`} 
        style={{ width: "100%", height: "400px" }} 
      ></iframe>
    ) : ['.jpg', '.jpeg', '.png'].some(ext => cvData.licence.endsWith(ext)) ? (
      <img 
        title="Licence Preview" 
        src={`https://kmsbe.frontieri.com/kmsApi/${cvData.licence}`} 
        alt="Licence Preview" 
        style={{ width: "100%", height: "400px", objectFit: "contain" }} 
      />
    ) : (
      <p>Unsupported file format</p>
    )}
  </>
)}


                <div className="mt-4">
                  <Upload
                    beforeUpload={beforeUpload}
                    fileList={fileList}
                    maxCount={1}
                    accept=".pdf, .jpg, .jpeg, .png"
                  >
                    <Button type="dashed" className="ant-full-box" icon={<ToTopOutlined />}>
                      Upload File
                    </Button>
                  </Upload>
                  <Button onClick={handleUpload} hidden={!fileList.length}>Submit</Button>
                </div>
              </Card>
            </Col>
          </Row>
          {/* Project Engagement */}
          <Row gutter={[24, 0]}>
            <Col span={24} md={24} className="mb-24">
              <Card
                bordered={false}
                title={<h6 className="font-semibold m-0">Project Engagement</h6>}
                className="header-solid h-full"
                bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
              >
                {/* Render the ProjectEngagement component passing the CV id */}
                <ProjectEngagement cvId={id} />
              </Card>
            </Col>
          </Row>
          {/* Project Evaluation */}
          <Row gutter={[24, 0]}>
            <Col span={24} md={24} className="mb-24">
              <Card
                bordered={false}
                title={<h6 className="font-semibold m-0">Project Evaluation</h6>}
                className="header-solid h-full"
                bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
              >
                <CvProjects cvId={id} />
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default HRCVDetail;
