import React, { useState, useEffect } from "react";
import { Table, Card, Tag, Row, Col, message, Input, Tooltip, Spin, Modal, Button } from "antd";
import { PlusCircleFilled, PlusCircleOutlined, SearchOutlined,StarOutlined } from "@ant-design/icons";
import axios from "axios";
import useFetchWithToken from "../../services/api";
import CvProjects from "./cvProjects"; // Make sure to import your CvProjects component
import CvProjectsWithList from "./cvProjectsWithList";

const { Search } = Input;



const getUniqueValues = (data, key) => {
  const uniqueValues = [...new Set(data.map(item => item[key]?.trim()).filter(Boolean))];
  return uniqueValues.map(value => {
    const trimmedValue = value.length > 40 ? `${value.substring(0, 40)}...` : value;
    return { text: trimmedValue, value: trimmedValue };
  });
};

function highlightMatchedText(text, query) {
  if (!text || !query || query.trim() === '') return text;

  const index = text.toLowerCase().indexOf(query.toLowerCase());
  if (index === -1) return text;

  const maxLength = 50;
  const startIndex = Math.max(0, index - maxLength);
  const endIndex = Math.min(text.length, index + query.length + maxLength);

  const prefix = startIndex > 0 ? '...' : '';
  const suffix = endIndex < text.length ? '...' : '';

  const highlightedText = text.substring(startIndex, endIndex)
    .replace(new RegExp(query, 'gi'), (match) => `<span style="background-color: yellow">${match}</span>`);

  return (
    <span dangerouslySetInnerHTML={{ __html: prefix + highlightedText + suffix }} />
  );
}
function HRCVEvaluation() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
  const [isModalVisible2, setIsModalVisible2] = useState(false); // State for modal visibility

  const [selectedCvId, setSelectedCvId] = useState(null); // State for selected CV ID
  const { token } = useFetchWithToken("cvs");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get("https://kmsbe.frontieri.com/kmsApi/hrcvProjects", {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const groupedData = response.data.reduce((acc, item) => {
          const cvId = item.cvInfo.id;
          if (!acc[cvId]) {
            acc[cvId] = {
              cvInfo: item.cvInfo,
              projects: []
            };
          }
          acc[cvId].projects.push(item);
          return acc;
        }, {});

        setData(Object.values(groupedData));
        setLoading(false);
      } catch (error) {
        message.error("Failed to load data");
        setLoading(false);
      }
    }

    fetchData();
  }, [token]);

  const columns = [
    {
      title: "Expert Name",
      dataIndex: ["cvInfo", "expertName"],
      key: "expertName",
      width: "15%",
      filters: getUniqueValues(data, 'cvInfo.expertName'),
      onFilter: (value, record) => record.cvInfo.expertName.includes(value),
      render: (text) => highlightMatchedText(text, searchQuery),
    },
    // {
    //     title: "Contact Info",
    //     dataIndex: ["cvInfo", "contactInformation"],
    //     key: "contactInformation",
    //     width: "15%",
    //     filters: getUniqueValues(data, 'cvInfo.contactInformation'),
    //     onFilter: (value, record) => record.cvInfo.contactInformation.includes(value),
    //     render: (text) => highlightMatchedText(text, searchQuery),
    //   },
    {
        title: "Location",
        dataIndex: ["cvInfo", "country"],
        key: "country",
        width: "15%",
        filters: getUniqueValues(data, 'cvInfo.country'),
        onFilter: (value, record) => record.cvInfo.country.includes(value),
        render: (text) => highlightMatchedText(text, searchQuery),
      },
      {
        title: "Research Interests",
        dataIndex: ["cvInfo", "researchInterest"],
        key: "researchInterest",
        width: "15%",
        filters: getUniqueValues(data, 'cvInfo.researchInterest'),
        onFilter: (value, record) => record.cvInfo.researchInterest.includes(value),
        render: (text) => highlightMatchedText(text, searchQuery),
      },
    {
      title: "Average Points",
      dataIndex: ["cvInfo", "averagePoints"],
      key: "averagePoints",
      width: "15%",
      filters: getUniqueValues(data, 'cvInfo.averagePoints'),
      onFilter: (value, record) => record.cvInfo.averagePoints.includes(value),
      render: (text) => {
        const points = text; // Assuming text is the averagePoints value
        const color = 
          points >= 3.5 ? 'green' :
          points > 2.5 ? 'orange' :
          'darkred';

        return (
          <div style={{
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            backgroundColor: color,
            display: 'inline-block',
            marginRight: '8px',
          }}>
          </div>
        );
      },
    },
  
    {
      title: "Projects",
      key: "projects",
      width: "70%",
      render: (text, record) => (
        <Row gutter={[8, 8]}>
          {record.projects.map(project => {
            const { points } = project.cvProjectInfo;
            const backgroundColor = points >= 3.5 ? 'green' :
                                  points > 2 ? 'darkorange' :
                                    'darkred';
            return (
              <Col key={`${project.cvProjectInfo.ProjectId}-${project.cvProjectInfo.CvId}`}>
                <Tooltip title={`${project.projectInfo.title} - ${project.cvProjectInfo.position}`}>
                  <Tag style={{ backgroundColor, color: 'white' }}>
                    {project.projectInfo.title.length > 20 ? `${project.projectInfo.title.substring(0, 20)}...` : project.projectInfo.title}
                    {" - "}
                    {project.cvProjectInfo.position.length > 20 ? `${project.cvProjectInfo.position.substring(0, 20)}...` : project.cvProjectInfo.position}
                    {" - "}
                    {project.cvProjectInfo.points.length > 20 ? `${project.cvProjectInfo.points.substring(0, 20)}...` : project.cvProjectInfo.points}
                  </Tag>
                </Tooltip>
              </Col>
            );
          })}
        </Row>
      ),
    },
    {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Button 
            type="primary" 
            // icon={<PlusCircleOutlined />}
            onClick={() => {
              setSelectedCvId(record.cvInfo.id); // Set the selected CV ID
              setIsModalVisible(true); // Show the modal
            }}
          >
            {/* Optionally add button text here */}
          <PlusCircleOutlined style={{ fontSize: '20px', color: 'white' }}  />
          </Button>
        ),
      }
  ];

  return (
    <Card>
    <Row justify="space-between">
    <Col>
          <Button
            type="primary"
            onClick={() => setIsModalVisible2(true)}
          >
            New Evaluation
          </Button>
        </Col>
        <Col>
          <Search
            placeholder="Search..."
            enterButton={<SearchOutlined />}
            onSearch={value => setSearchQuery(value)}
          />
        </Col>
     
      </Row>
      {loading ? (
        <Spin tip="Loading..." />
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={data}
            rowKey={record => record.cvInfo.id}
          />
          <Modal
            title="CV Projects"
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
            width={1000} // You can adjust the width
          >
            <CvProjects cvId={selectedCvId} />
          </Modal>

          <Modal
            title="New Evaluation"
            visible={isModalVisible2}
            onCancel={() => setIsModalVisible2(false)}
            footer={null}
            width={1000} // You can adjust the width
          >
            <CvProjectsWithList  />
          </Modal>
        </>
      )}
    </Card>
  );
}

export default HRCVEvaluation;
